._filters .filter {
    display: flex;
    width: 75%;
    justify-content: space-between;
}

._filters .filter-value-set {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

._filters .flip {
    transform: rotate(180deg);
}

._filters .switch-filters {
    border-radius: 8px;
    background-color: var(--white);
    padding: 18.5px 15px;
    margin-bottom: 16px;
    border: 1px solid var(--brand-md-grey);
}

._filters .switch-filter {
    color: var(--brand-md-blue-2);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    line-height: 16px;
    font-weight: 600;
}

._filters .filter-values div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 500;
    width: 60px;
}

._filters .filter-values div:hover {
    cursor: pointer;
}

._filters .filter-values div+.on {
    padding: 4px 4px 4px 8px;
}

._filters .switch-filter .on {
    background-color: var(--brand-md-blue-2);
    color: var(--white);
    padding: 4px 4px 4px 8px;
}

._filters .switch-filter .on .circle {
    margin-right: 0px;
}

._filters .switch-filter .off {
    background-color: var(--extended-light-blue);
    color: var(--brand-md-blue);
    padding: 4px 8px 4px 4px;
}

._filters .switch-filter .off .circle {
    margin-left: 0px;
}

._filters .filter-values .circle {
    width: 18px;
    height: 18px;
    border-radius: 4px;
    background-color: var(--white);
    margin: 0px 5px;
}

._filters .value {
    margin-left: 15px;
    display: flex;
    gap: 3px;
    margin-bottom: 6px;
}

._filters .value input:focus-visible {
    border: 0;
    outline: 3px solid var(--brand-md-blue);
}

._filters .sub-filter {
    margin-left: 30px;
}

._filters .multi-filters {
    background-color: var(--white);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    margin-bottom: 10px;
    border: 1px solid var(--brand-md-grey);
}

._filters .multi-filters .multi-filter {
    border-bottom: 1px solid var(--brand-md-grey);
    padding-bottom: 10px;
}

._filters .multi-filters .multi-filter:last-child {
    border-bottom: 0;
}

._filters .multi-filter__label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    padding: 18px 25px 18px 17px;
    color: var(--brand-md-blue-2);
    cursor: pointer;
}

._filters .multi-filter__label:focus-visible {
    border: 0;
    outline: 4px solid var(--brand-md-blue);
}

._filters .multi-filter__content .filter-value {
    max-height: 246px;
    overflow-y: auto;
    margin-right: 22px;
}

._filters .multi-filter__content {
    margin-top: -10px;
}

._filters input[type=checkbox] {
    accent-color: var(--brand-dark-blue);
}

@media only screen and (max-width: 1199px) {
    ._filters .switch-filter {
        flex-direction: column;
    }
}