._admin-bar {
  background-color: var(--brand-md-blue);
  width: 100%;
}

._admin-bar .title {
  font-size: 24px;
  font-weight: 300;
  line-height: 26px;
  color: var(--white);
}

._admin-bar .nav button {
  color: var(--white);
  font-size: 20px;
  font-weight: 700;
  line-height: 24.2px;
  padding-bottom: 7px;
  text-underline-offset: 7px;
  text-decoration: none;
  overflow-wrap: normal;
  border: 0;
  background: transparent;
  cursor: pointer;   
}

._admin-bar .nav button:focus-visible {
  border: 0;
  outline: 4px solid var(--white);
}


._admin-bar .nav .current {
  text-underline-offset: 7px;
  text-decoration: underline;
}

._admin-bar .right-content .btn-secondary {
  padding: 9px 30px;
  border-radius: 8px;
}

._admin-bar .right-content .account {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  color: var(--white);
  cursor: pointer;
}

@media only screen and (min-width: 600px) {
  ._admin-bar {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  ._admin-bar__content {
    padding: 24px 24px 24px 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    max-width: 1440px;
  }

  ._admin-bar .title {
    max-width: 162px;
    overflow-wrap: normal;
  }

  ._admin-bar .nav {
    display: flex;
    justify-content: flex-start;
    flex-grow: 1;
    max-width: 422px;
    margin-left: 98px;
  }

  ._admin-bar .right-content {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
  }

  ._admin-bar .right-content .spacer {
    flex-grow: 2;
  }

  ._admin-bar .right-content .right-content__content {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
  }

  ._admin-bar .right-content .btn-secondary {
    white-space: nowrap;
  }

    ._admin-bar .right-content .btn-secondary:focus-visible {
      border: 2px solid var(--white);
      outline: 2px solid var(--brand-md-blue);
    }

    ._admin-bar .right-content .account {
      white-space: nowrap;
      display: flex;
      align-items: center;
      margin-left: 33px;
    }

    ._admin-bar .right-content .account img {
      margin-left: 7px;
      padding-top: 3px;
    }
    .account_profile__menu {
      display: flex;
      position: absolute;
      top: 67px;
      width: 100px;
      background-color: var(--white);
      box-shadow: 0px 0px 4px rgb(0 0 0 / 12%);
      border-radius: 5px;
      padding: 4px;
      z-index: 1;
      justify-content: center;
    }
    .account_profile__menu_logout {
      border: 0;
      background: none;
      color: var(--brand-md-blue);
      font-weight: 500;
      font-size: 18px;
      line-height: 19px;
      font-family: "Inter";
    }

    .account_profile__menu:focus-within {
      border: 3px solid var(--brand-md-blue);
      outline: 2px solid var(--white);
    }

    #account_profile:focus-visible {
      border: 0;
      outline: 4px solid var(--white);
    }
}

@media only screen and (min-width: 1488px) {
  ._admin-bar__content {
    padding-left: 0px;
  }
}
