._login {
    display: flex;
    flex-direction: column;
    width: 31%;
    margin: 9% auto;
}

h1._login__header {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    margin: 0;
}

._login__hr {
    border: 0;
    height: 0;
    border-top: 1px solid var(--brand-md-grey);
    border-bottom: 1px solid var(--brand-md-grey);
    width: 100%;
    margin: 24px 0;
}

._login__form_label {
    margin: 10px 0 5px 0;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
}

._login__form_row {
    margin-bottom: 40px;
}

._login__form_input {
    margin: 5px 0 0 0;
    padding: 8px 16px; 
    border: 1px solid var(--brand-md-grey);
    border-radius: 5px;
    box-sizing: border-box;
    width: 100%;
}

._login__form_submit {
    margin: 10px 0 0 0;
    padding: 12px 25px;
    border: 2px solid var(--brand-dark-blue);
    border-radius: 8px;
    background: var(--brand-dark-blue);
    width: 48%; 
    font-size: 16px;
    color: white;
    line-height: 19px;
    display: block;
    cursor: pointer;
}

._login__form_submit:disabled {
    background: #ccc;
    cursor: not-allowed;
    border: 2px solid #ccc;
}

._login__form_disclaimer {
    margin: 0 0 32px 0;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    font-style: italic;
    color: var(--brand-dark-gray);
    white-space: nowrap;
}

._login ._login__forgot_password {
    border: 0;
    background: none;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 16px;
    color: var(--brand-dark-blue);
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
    padding: 22px 0 0 0;
}

._login__forgot_password__text {
    color: var(--brand-dark-gray);
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    padding-top: 8px;
}

@media screen and (max-width: 600px) {
    ._login {
        width: 100%;
        padding: 0 20px;
    }
    ._login__form_submit {
        width: 100%;
    }
    ._login__form_disclaimer {
        white-space: normal;
    }
}
