._create-resource {
    background-color: var(--light-grey);
    margin-bottom: 5px;
    width: 100%;
}

._create-resource .section-title {
    font-size: 20px;
    font-weight: 400;
    color: var(--black);
    margin-bottom: 22px;
}

._create-resource .divider {
    width: 100%;
    margin: 25px 0px;
    background-color: var(--brand-md-grey);
    border: none;
    height: 2px;
}

._create-resource .input {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: var(--black);
}

._create-resource input {
    display: block;
    height: 40px;
    box-sizing: border-box;
    border: 1px solid var(--brand-md-grey);
    border-radius: 5px;
    margin: 8px 0px;
    padding: 8px 16px;
}

._create-resource input:focus-visible {
    border: 0;
    outline: 2px solid var(--brand-md-blue);
}

._create-resource input[type="text"]:focus {
    border: 2px solid var(--brand-md-blue);
    outline: none;
}

._create-resource .text-area {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: var(--black);
}

._create-resource textarea {
    display: block;
    box-sizing: border-box;
    margin: 8px 0px;
    background: var(--white);
    ;
    border: 1px solid var(--brand-md-grey);
    padding: 8px 16px;
}

._create-resource textarea::placeholder {
    font-family: Arial;
}

._create-resource textarea[type="textarea"]:focus {
    border: 2px solid var(--brand-md-blue);
    outline: none;
}

._create-resource input[type="checkbox"] {
    width: 24px;
    margin: 16px;
}

._create-resource .input-field {
    background: var(--white);
    border: 1px solid var(--brand-md-grey);
    border-radius: 5px;
    height: 40px;
    box-sizing: border-box;
    position: absolute;
    margin-top: 8px;
}

._create-resource .section-row-seperator {
    margin: 25px 0px;
}

._create-resource .section-topic {
    margin: 25px 0px;
}

._create-resource .section-field {
    margin-left: 20px;
    flex-grow: 2;
}

._create-resource .section-cost-field {
    margin-left: 20px;
    margin-top: 10px;
    flex-grow: 2;
}

._create-resource .btn-secondary {
    color: var(--white);
    width: 213px;
    height: 44px;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
    background: var(--brand-md-blue);
    border: 2px solid var(--brand-md-blue);
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    text-align: center;
}

._create-resource .btn-secondary:focus-visible {
    border: 2px solid var(--white);
    outline: 4px solid var(--brand-md-blue);
    outline-offset: unset;
}

._create-resource .btn-secondary:hover {
    font-weight: 600;
}

._create-resource .btn-secondary:disabled {
    background: var(--brand-md-grey);
    cursor: not-allowed;
    border: 2px solid var(--brand-md-grey);
    color: white;
}

._create-resource .checkbox-section {
    display: flex;
    background: var(--extended-light-blue);
    ;
    border-radius: 8px;
}

._create-resource .checkbox-label {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: var(--black);
}

._create-resource .back {
    color: var(--brand-dark-blue);
    margin: 32px 0px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    cursor: pointer;
}

._create-resource .back:hover {
    font-weight: 800
}

._create-resource .character-count {
    color: var(--brand-dark-gray);
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
}

._create-resource .form-error {
    color: var(--brand-red);
    font-size: 16px;
    font-weight: 600;
}

._create-resource .field-set {
    border: none;
}

.lg-input-field:focus-visible,
.md-content-provider:focus-visible,
.vsm-input-field:focus-visible,
.sm-text-area:focus-visible,
.md-text-area:focus-visible,
.sm-input-field-credit-hours:focus-visible,
.md-input-field:focus-visible,
.lg-text-area:focus-visible {
    border: 0;
    outline: 2px solid var(--brand-md-blue);
}

._create-resource .downloadFile {
    background-color: var(--light-grey-2);
    padding: 20px;
    color: var(--brand-md-blue)
}

._create-resource .downloadIcon {
    margin-left: 20px;
}

._create-resource .error-message{
    color: var(--brand-red);
    margin: 12px 0px;
    font-size: 14px;
    font-weight: 400;
}

._create-resource #create-url {
    color: var(--brand-md-blue);
}

@media only screen and (min-width: 600px) {

    ._create-resource .divider {
        width: 100%;
        margin: 25px 0px 0px 0px;
    }

    ._create-resource .form-error {
        margin-top: 5px;
    }

    ._create-resource .input {
        flex-grow: 1;
    }

    ._create-resource .publish-dates .input {
        flex-grow: 0;
    }

    ._create-resource .section-row {
        margin: 25px 0px;
        display: flex;
        width: 100%;
    }

    ._create-resource .disabled {
        display: block;
    }

    ._create-resource .disabled .label {
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        margin-bottom: 10px;
    }

    ._create-resource .disabled .comments {
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
    }

    ._create-resource .lg-input-field {
        max-width: 690px;
        width: 75%;
    }

    ._create-resource .lg-input-field .invalid {
        border: 1px solid var(--brand-red);
    }

    ._create-resource .sm-input-field {
        max-width: 335px;
        width: 100%;
    }

    ._create-resource .vsm-input-field {
        height: 44px;
        max-width: 99px;
        margin-top: 11px;
    }

    ._create-resource .md-input-field {
        max-width: 511px;
        width: 100%;
    }

    ._create-resource .text-area {
        flex-grow: 1;
    }

    ._create-resource .publish-dates .text-area {
        flex-grow: 0;
    }

    ._create-resource .publish-date {
        max-width: 360px;
        width: 35%;
        flex-grow: 1;
    }

    ._create-resource .lg-text-area {
        height: 200px;
        max-width: 907px;
        width: 84%;
    }

    ._create-resource .md-text-area {
        max-width: 571px;
        height: 200px;
        width: 100%;
    }

    ._create-resource .sm-text-area {
        max-width: 453px;
        height: 100px;
        width: 100%;
    }

    ._create-resource .checkbox-section {
        max-width: 335px;
        width: 40%;
        height: 70px;
        display: flex;
        align-items: center;
    }

    ._create-resource .md-content-provider {
        max-width: 473px;
        width: 100%;
        height: 36px;
        margin-top: 8px;
    }

    ._create-resource .md-dropdown-field {
        max-width: 335px;
        width: 35%;
        height: 36px;
        margin-top: 8px;
    }

    ._create-resource .lg-dropdown-field {
        max-width: 688px;
        height: 36px;
        width: 68%;
        margin-top: 8px;
    }

    ._create-resource .sm-dropdown-field {
        max-width: 216px;
        width: 55%;
        height: 40px;
    }

    ._create-resource .sm-dropdown-field-credit {
        max-width: 216px;
        width: 100%;
    }

    ._create-resource .section-row-credit {
        margin: 25px 0px;
        display: flex;
        width: 100%;
        gap: 20px;
    }

    ._create-resource .section-row-credit ._dropdownField {
        flex-grow: 0;
    }

    ._create-resource .section-row-credit .credit-type {
        max-width: 216px;
        width: 20%;
    }


    ._create-resource .sm-input-field-credit-hours {
        height: 46px;
        max-width: 99px;
        margin-top: 12px;
        max-width: 216px;
        width: 100%;
        flex-grow: 0;
    }
}