._user_survey {
    width: 48.6%;
    display: flex;
    flex-direction: column;
    margin: 90px auto;
}

._user_survey__role, ._user_survey__org_type {
    width: 50%;
    margin-bottom: 32px;
    font-size: 16px;
}

._user_survey__zip_code {
    display: block;
    height: 40px;
    box-sizing: border-box;
    border: 1px solid var(--brand-md-grey);
    border-radius: 5px;
    margin: 8px 0px;
    padding: 8px 16px;
    width: 32%;
    font-size: 16px;
}

._user_survey__zip_code:focus-visible {
    border: 0;
    outline: 4px solid var(--brand-md-blue);
}

._user_survey .input {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: var(--black);
    margin-bottom: 32px;
}

._user_survey__description {
    margin: 0 0 14px 0;
    line-height: 20px;
}

._user_survey__submit_wrapper {
    display: flex;
    flex-direction: row;
    width: 64%; 
    gap: 16px;
}

._user_survey__submit {
    margin: 0 0 0 0;
    padding: 12px 25px;
    border: 2px solid var(--brand-md-blue);
    border-radius: 8px;
    background: var(--brand-md-blue);
    width: 48%; 
    font-size: 16px;
    color: var(--white);
    line-height: 19px;
    font-family: 'Inter';
    font-weight: 500;
    display: block;
    cursor: pointer;
}

._user_survey__submit:focus-visible {
    border: 2px solid var(--white);
    outline: 4px solid var(--brand-md-blue);
}

._user_survey__submit:disabled, ._user_survey__skip:disabled {
    background: var(--brand-md-grey);
    cursor: not-allowed;
    border: 2px solid var(--brand-md-grey);
    color: white;
}

._user_survey__skip {
    margin: 0 0 0 0;
    padding: 12px 25px;
    border: 2px solid var(--brand-md-blue);
    border-radius: 8px;
    background: var(--white);
    width: 46%; 
    font-size: 16px;
    color: var(--brand-md-blue);
    line-height: 19px;
    display: block;
    font-family: 'Inter';
    font-weight: 500;
    cursor: pointer;
}

._user_survey__skip:focus-visible {
    border: 0;
    outline: 4px solid var(--brand-md-blue);
}

._user_survey .collapsed {
    border: none;
    margin-top: 8px;
}

._user_survey .expanded {
    border: none;
    margin-top: 8px;
}
