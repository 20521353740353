._manage {
  background-color: var(--light-grey);
}

._manage .content .actions button {
  min-height: 83px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--brand-md-blue);
  border-radius: 10px;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  overflow-wrap: normal;
  word-break: normal;
  text-align: left;
  padding: 0px;
}

._manage .load-more button {
  border-radius: 24px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  color: var(--brand-md-blue);
}

._manage .filter {
  min-width: 155px;
}

._manage ._drop-down {
  background-color: var(--white);
  padding: 8px 15px;
  border-radius: 4px;
}

._manage .expanded {
  border-radius: 4px 4px 0px 0px;
}

._manage ._drop-down .dropdown-selection {
  top: 35px;
  left: -1px;
}

._manage .content .actions button.alternate {
  border: 1px dashed var(--brand-md-blue);
  background-color: var(--extended-light-blue);
}

._manage .content .row-primary {
  background-color: var(--white);
}

._manage .back {
  color: var(--brand-dark-blue);
  margin: 3px 0px 32px 0;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  cursor: pointer;
}

.results-total-field{
  font-size: 16px;
  font-weight: 500;
}

.results-divider{
  border-bottom: 0px;
}

._manage .back:hover {
  font-weight: 800;
}

._manage .successMessage {
  padding: 20px;
  font-size: 18px;
  color: var(--brand-green);
}

._manage .content .row .check-box {
  width: 15px;
  height: 15px;
  background-color: var(--brand-dark-grey);
  display: flex;
  justify-content: center;
  align-items: center;
}

._manage .content .row .user-roles {
  display: flex;
  flex-direction: row;
}

._manage .content .row .user-role {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

._manage .content .row .user-fname,
._manage .content .row .user-lname,
._manage .content .row .user-org,
._manage .content .row .user-edit {
  font-size: 16px;
}

._manage .content .row .user-role .check-box {
  margin-bottom: 8px;
  border-radius: 2px;
}

._manage .content .row {
  color: var(--brand-dark-grey);
}

._manage .content .row .user-edit {
  color: var(--brand-dark-blue);
  text-decoration: underline;
}

._manage .content .row .check-box {
  width: 15px;
  height: 15px;
  background-color: var(--brand-dark-grey);
  display: flex;
  justify-content: center;
  align-items: center;
}

._manage .content .row .user-roles {
  display: flex;
  flex-direction: row;
}

._manage .content .row .user-role {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

._manage .content .row .user-fname,
._manage .content .row .user-lname,
._manage .content .row .user-org,
._manage .content .row .user-edit {
  font-size: 16px;
}

._manage .content .row .user-edit:hover {
  font-weight: 600;
  cursor: pointer;
}

._manage .content .row .user-role .check-box {
  margin-bottom: 8px;
  border-radius: 2px;
}

._manage .content .row {
  color: var(--brand-dark-grey);
}

._manage .content .row .user-edit {
  color: var(--brand-dark-blue);
  text-decoration: underline;
}

@media only screen and (min-width: 600px) {
  ._manage {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  ._manage .main {
    max-width: 1440px;
    display: flex;
    padding: 33px 20px 48px 20px;
    width: 100%;
  }

  ._manage .utility {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 335px;
  }

  ._manage .content {
    padding-left: 20px;
    width: 73%;
  }

  ._manage .content .actions {
    display: flex;
    width: 100%;
  }

  ._manage .content .results-control {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 24px 10px;
  }

  ._manage .no-results {
    padding: 20px;
    font-size: 20px;
  }

  ._manage .content .actions button,
  ._manage .load-more button,
  ._manage .load-more .void {
    max-width: 335px;
    min-width: 126px;
    width: 33%;
    margin-left: 10px;
    margin-right: 10px;
  }

  ._manage .load-more {
    display: flex;
    margin-top: 48px;
  }

  ._manage .content .actions button>img {
    margin-right: 22px;
  }

  ._manage .content .row {
    max-width: 1045px;
    height: 80px;
    flex-grow: 1;
    display: flex;
    flex-direction: 'row';
    align-items: center;
  }

  ._manage .content .row .img {
    min-width: 230px;
    display: flex;
    align-items: center;
    padding-left: 16px;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  ._manage .content .row img {
    max-width: 200px;
    max-height: 50px;
    object-fit: contain;
  }

  ._manage .content .row .row-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-grow: 1;
  }

  ._manage .content .field-names .modify {
    word-wrap: break-word;
  }

  ._manage .content .row .org-edit,
  ._manage .content .row .user-edit {
    margin-right: 56px;
    word-wrap: break-word;
  }

  ._manage .content .row .org-edit {
    color: var(--brand-dark-blue);
    text-decoration: underline;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
  }

  ._manage .content .row .org-edit:hover,
  ._manage .content .row .org-name:hover {
    cursor: pointer;
  }

  ._manage .content .org-field-names,
  ._manage .content .field-names {
    display: flex;
    flex-direction: row;
    max-width: 1045px;
    margin-bottom: 16px;
  }

  ._manage .content .org-field-names .org {
    width: 230px;
    padding-left: 16px;
  }

  ._manage .content .org-field-names .modify {
    flex-grow: 1;
    text-align: right;
    margin-right: 43px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1479px) {

  ._manage .content .org-field-names,
  ._manage .content .field-names {
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 500;
  }

  ._manage .content .field-names .fname,
  ._manage .content .row .user-fname,
  ._manage .content .field-names .lname,
  ._manage .content .row .user-lname,
  ._manage .content .field-names .org,
  ._manage .content .row .user-org,
  ._manage .content .field-names .role,
  ._manage .content .row .user-role,
  ._manage .content .row .user-edit {
    margin-left: 5px;
    word-wrap: break-word;
  }

  ._manage .content .row {
    height: auto;
    min-height: 80px;
  }

  ._manage .content .user-row {
    justify-content: space-between;
  }

  ._manage .content .field-names .fname,
  ._manage .content .row .user-fname {
    margin-left: 17px;
    min-width: 17.7%;
  }

  ._manage .content .field-names .lname,
  ._manage .content .row .user-lname {
    min-width: 18.4%;
  }

  ._manage .content .field-names .org,
  ._manage .content .row .user-org {
    min-width: 22%;
    padding-right: 5px;
  }

  ._manage .content .field-names .role {
    min-width: 31.1%;
  }

  ._manage .content .row .user-roles {
    min-width: 32%;
  }

  ._manage .content .field-names .modify,
  ._manage .content .row .user-edit {
    flex-grow: 1;
  }
}

@media only screen and (min-width: 1480px) {
  ._manage .main {
    padding-left: 0px;
  }

  ._manage .content .field-names .fname,
  ._manage .content .row .user-fname {
    margin-left: 17px;
    max-width: 185px;
    flex-grow: 1;
  }

  ._manage .content .field-names .lname,
  ._manage .content .row .user-lname {
    max-width: 192px;
    flex-grow: 1;
  }

  ._manage .content .field-names .org,
  ._manage .content .row .user-org {
    max-width: 231px;
    padding-right: 10px;
    flex-grow: 1;
  }

  ._manage .content .field-names .role {
    max-width: 325px;
    flex-grow: 1;
  }

  ._manage .content .row .user-roles {
    max-width: 336px;
    flex-grow: 1;
  }

  ._manage .content .row .user-role {
    margin-right: 23px;
  }
}