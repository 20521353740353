._details {
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
._details .toolbar {
    padding: 30px 0px 19px 21px;
    width: 100%;
    background-color: var(--white);
}

._details .toolbar .to-results {
    color: var(--black-1);
    cursor: pointer;
}

._details .toolbar .to-results:focus-visible {
    border: 0;
    outline: 4px solid var(--brand-md-blue);
}

._details__content {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: center;
   width: 100%;
}

._details .source {
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    order: 2;
    padding: 0px;
}

._details .source .title {
    font-weight: 900;
}

._details .summary .logo-container{
    display: flex;
    margin-bottom: 25px;
    margin-top: 5px;
}

._details .summary .container{
    width: 25%;
    margin-right: 20px;
}

._details .summary .container img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

._details .summary .buttons-section{
    display: inline;
    margin-bottom: 35px;
    align-self: center;
}

._details .source .metadata {
    padding-left: 10px;
    margin-bottom: 24px;
    margin-right: 35px;
}

._details .source .metadata .label {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: var(--brand-dark-gray);
    margin-bottom: 8px;
}

._details .source .metadata .item {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    max-width: 400px;
    margin-bottom: 8px;
}

._details .summary {
    flex-basis: 60%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    order: 1;
    width: 95%
}

._details .summary .title {
    font-size: 22px;
    line-height: 26px;
    font-weight: 700;
    margin-bottom: 16px;
}

._details .summary .util {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 19px;
    flex-direction: column;
}

._details .summary .util-left {
    display: flex;
    justify-content: flex-start;
    flex-grow: 1;
    margin-bottom: 20px;
    margin-right: 15px;
}

._details .summary .util .endorsed {
    background-color: var(--white);
    color: var(--brand-md-blue);
    border-radius: 14px;
    padding: 6px 0px;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    width: 150px;
    text-transform: uppercase;
}

._details .summary .util .endorsed img {
    margin-right: 8px;
}

._details .summary .title-section .logo-container .orgName {
    align-self: center;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
}

._details .summary .title-section .logo-container .orgName .creatorTag {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: var(--brand-dark-grey);    
}

._details .summary .description {
    width: 100%;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 40px;
    white-space: pre-wrap;
}

._details .summary .listing {
    background-color: var(--brand-dark-blue);
    color: var(--white);
    font-size: 16px;
    font-weight: 500;
    width: 330px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    border-radius: 8px;
    border: 2px solid var(--brand-dark-blue);
}

._details .summary .listing:focus-visible {
    border: 2px solid var(--white);
    outline: 3px solid var(--brand-dark-blue);
}

._details .summary .listing {
    text-decoration: none;
}

._details .summary .buttons-section .share {
    background-color: var(--white);
    color: var(--brand-dark-blue);
    font-size: 16px;
    font-weight: 500;
    width: 330px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--brand-dark-blue);
    cursor: pointer;
}

._details .summary .buttons-section .share.copied {
    background-color: var(--brand-md-blue);
    color: var(--white);
    text-align: center;
    font-size: 15px;
}

._details .summary .buttons-section .share:hover {
    border: 2px solid var(--brand-dark-blue);
    font-weight: 600;
}

._details .summary .buttons-section .share:focus-visible {
    border: 0;
    outline: 4px solid var(--brand-md-blue);
}

._details .summary .buttons-section .share img {
    margin-right: 5px;
    height: 16px;
}

._details .white-space {
    flex-basis: 25%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 28px;
    margin: 44px 0px;
    order: 3;
}

._details .white-space .lo__label {
    margin-top: 5px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: var(--brand-md-blue);
}

._details .white-space .lo__content {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
}

._details .source .source-heading-section-event {
    background-color: var(--brand-media-type-event);
    height: 105px;
    border-radius: 7px 7px 0px 0px;
    margin-right: -0.5px;
}

._details .source .source-heading-section-recording {
    background-color: var(--brand-media-type-recording);
    height: 105px;
    border-radius: 7px 7px 0px 0px;
    margin-right: -0.5px;
}

._details .source .source-heading-section-document {
    background-color: var(--brand-media-type-document);
    height: 105px;
    border-radius: 7px 7px 0px 0px;
    margin-right: -0.5px;
}

._details .source .source-heading-section-course {
    background-color: var(--extended-light-blue);
    height: 105px;
    border-radius: 7px 7px 0px 0px;
    margin-right: -0.5px;
}

._details .source .source-content {
    padding: 20px;
}

._details .heading-content {
    text-transform: uppercase;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 16px;
    display: flex;
    flex-basis: 100%;
    padding: 14px;
}

._details .event-type {
    display: flex;
    flex-grow: 1;
    flex-basis: 40%;
}

._details .heading-item-title {
    padding: 20px;
}

._details .date-cost {
    padding: 20px 0px;
    flex-grow: 4;
    display: flex;
    flex-basis: 50%;
}

._details .heading-item-date  {
    flex-grow: 1;
    margin-right: 6px;
}

._details .heading-item  {
    flex-grow: 1;
}

._details .source .source-details {
    border: 1px solid var(--brand-md-grey);
    border-radius: 8px;
}

._details .source .source-details .source-content .sections .section{
    border-bottom: 1px solid var(--brand-md-grey);
    margin: 20px 0px;
}

._details .source .source-details .source-content .sections .section-last{
    margin: 20px 0px;
    display: flex;
}

._details .source .source-details .source-content .sections .section-last .grade-span{
    flex-basis: 45%;
}

._details .summary .description .learning-objectives {
    margin: 34px 0px;
}

._details .summary .description hr.divider {
    margin: 25px 0px;
    border-top: 1px solid var(--brand-md-grey);
}

._details .summary .description .label {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: var(--brand-dark-gray);
    margin-bottom: 8px;
}

._details .summary .description .sub-section {
    margin: 25px 0px;
}

._details .summary .description .sub-section .learning-objectives-text {
    line-height: 26px;
}

._details .summary .description .sub-section ol.teaching-standards {
    list-style-type: none;
    display: flex;
    padding-inline-start: 5px;
}

._details .summary .description .sub-section .teaching-standards .list-item-text{
    margin: 0px 10px;
}

._details__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 20px;
    padding-left: 16px;
    padding-right: 0px;
    padding-bottom: 32px;
}


@media only screen and (min-width: 600px) {

    ._details {
        padding: 0px 20px 28px 20px;
        display: flex;
        width: 100%;
        justify-content: center;
    }

    ._details__wrapper {
        max-width: 1440px;
        width: 100%;
    }

    ._details .toolbar {
        background: none;
        padding: 0px;
        margin: 31px 0px 23px 0px;
    }

    ._details__content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        padding-top: 20px;
        padding-left: 24px;
        padding-right: 0px;
        padding-bottom: 32px;
    }

    ._details .source {
        justify-content: space-evenly;
        align-items: initial;
        order: 2;
        padding: 0px;
        border-bottom: none;
        flex-basis: 40%;

    }

    ._details .summary .logo {
        margin-top: 0px;
    }
    
    ._details .summary {
        justify-content: space-evenly;
        order: 2;
        border-bottom: none;
        flex-basis: 50%;
    }

    ._details .summary .util {
        flex-direction: row;
        margin-bottom: 34px;
        justify-content: flex-start;
        
    }

    ._details .summary .util-left {
        margin-bottom: 0px;
        margin-right: 0px;
        flex-grow: 0;
        margin-right: 11px;
    }

    ._details .summary .description {
        margin-bottom: 34px;
    }

    ._details .summary .listing {
        margin-bottom: 10px;
        width: 342px;
    }
    
    ._details .white-space {
        justify-content: space-evenly;
        align-items: center;
        order: 3;
        margin: 0px;
    }

    ._details .lo {
        max-width: 297px;
    }

    ._details .white-space .lo__label {
        margin-bottom: 4px;
    }

    ._details .summary .buttons-section{
        display: flex;
        align-self: flex-start;
    }

    ._details .summary .listing {
        width: 217px;
        height: 44px;
    }

    ._details .summary .buttons-section .share{
        width: 140px;
        height: 44px;
        margin-left: 20px;
    }

    ._details .source .source-heading-section-course {
        background-color: var(--extended-light-blue);
        height: 77px;
        border-radius: 7px 7px 0px 0px;
        margin-right: -0.5px;
    }

    ._details .source .source-heading-section-event {
        background-color: var(--brand-media-type-event);
        height: 77px;
        border-radius: 7px 7px 0px 0px;
        margin-right: -0.5px;
    }
    
    ._details .source .source-heading-section-recording {
        background-color: var(--brand-media-type-recording);
        height: 77px;
        border-radius: 7px 7px 0px 0px;
    }
    
    ._details .source .source-heading-section-document {
        background-color: var(--brand-media-type-document);
        height: 77px;
        border-radius: 7px 7px 0px 0px;
    }
}

@media only screen and (max-width: 830px) { 
    ._details__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
    }

    ._details .source .metadata {
        padding-left: 0;
    }

    ._details .white-space {
        flex-basis: 25%;
    }

    ._details .source {
        flex-basis: 50%;
        align-items: normal;
    }

    ._details .heading-content {
        display: inline;
    }

    ._details .event-type{
        display: inline-flex;
        flex-grow: 1;
        padding-top: 4px;
    }
    ._details .date-cost{
        flex-grow: 3;
        padding: 14px;
    }

    ._details .source .source-heading-section-course {
        background-color: var(--extended-light-blue);
        height: 105px;
        border-radius: 7px 7px 0px 0px;
        margin-right: -0.5px;
    }

    ._details .source .source-heading-section-event {
        background-color: var(--brand-media-type-event);
        height: 105px;
        border-radius: 7px 7px 0px 0px;
        margin-right: -0.5px;
    }
    
    ._details .source .source-heading-section-recording {
        background-color: var(--brand-media-type-recording);
        height: 105px;
        border-radius: 7px 7px 0px 0px;
    }
    
    ._details .source .source-heading-section-document {
        background-color: var(--brand-media-type-document);
        height: 105px;
        border-radius: 7px 7px 0px 0px;
    }
}

