._view-user .btn-secondary {
    color: var(--white);
    width: 166px;
    height: 44px;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
    background: var(--brand-md-blue);
    border: 2px solid var(--brand-md-blue);
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    text-align: center;
}

._view-user .btn-secondary:hover {
    font-weight: 700;
}

input.disabled-checkbox:checked {
    accent-color: var(--brand-dark-grey);
    cursor: not-allowed;
}

._view-user .btn-secondary:disabled {
    border-radius: 8px;
    background: var(--brand-md-grey);
    cursor: not-allowed;
    border: 2px solid var(--brand-md-grey);
    color: white;
    cursor:not-allowed;
}

._view-user .btn-secondary:disabled:hover {
    font-weight: 500;
}

._view-user .btn-primary {
    color: var(--brand-md-blue);
    width: 155px;
    height: 44px;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
    background: var(--white);
    border: 2px solid var(--brand-md-blue);
    border-radius: 8px;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    text-align: center;
    margin-left: 10px;
}

._view-user .btn-primary:hover {
    font-weight: 600;
}

._view-user .deactivate-user {
    width: 188px;
}

._view-user .form-data {
    margin: 40px 20px;
}

._view-user .title {
    font-weight: 600;
}

._view-user .section-row {
    display: flex;
    margin: 20px 0px;
}

._view-user input {
    height: 40px;
    box-sizing: border-box;
    border: 1px solid var(--brand-md-grey);
    border-radius: 5px;
    font-style: normal;
    font-size: 16px;
    line-height: 16px;
    color: var(--black);
    margin: 8px 0px;
    padding: 8px 16px;
}

.input input[type="text"]:focus {
    border: 2px solid var(--brand-md-blue);
    outline: none;
}

._view-user .field {
    width: 220px
}

._view-user .field-value {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin: 20px 0px;
    color: var(--brand-dark-grey);
}

._view-user .roles-section {
    margin: 20px 0px;
}

._view-user .checkbox-section {
    margin: 10px 0px;
    align-items: center;
}

._view-user .checkbox-label {
    align-items: center;
    margin-left: 5px;
}

._view-user .form-data .roles-section .checkbox-section input[type="checkbox"] {
    height: 15px;
}

._view-user .form-data .roles-section .checkbox-section input[type="checkbox"]:disabled {
    color: var(--brand-md-blue);
}

._view-user .form-data .error {
    color: var(--brand-red);
    padding: 20px;
}

@media only screen and (min-width: 600px) {
    ._view-user .md-content-provider {
        max-width: 473px;
        width: 100%;
        height: 46px;
        border-radius: 5px;
        margin-top: 8px;
    }

    ._view-user .field-value {
        max-width: 435px;
    }

    ._view-user .email-field {
        width: 430px
    }

    ._view-user .md-input-field {
        display: block;
        max-width: 335px;
        width: 90%;
        padding: 10px;
        margin-top: 8px;
        border-radius: 5px;
        margin-right: 6.4em;
    }
}