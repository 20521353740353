._filter-modal {
    display: flex;
    flex-direction: column;
    color: var(--brand-dark-blue);
    background-color: var(--white);
    position: absolute;
    left: 0;
    bottom: 0;
    height: auto;
    width: 100%;
    border-radius: 8px 8px 0px 0px;
}

._filter-modal .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
    padding: 24px 24px 22px 24px;
}

._filter-modal .head .back,
._filter-modal .head .icon
{
    cursor: pointer;
}

._filter-modal .back {
    height: 100%;
    display: flex;
    align-items: center;
}

._filter-modal .left {
    transform: rotate(90deg);
}

._filter-modal .tilt-45 {
    transform: rotate(45deg);
}

._filter-modal .clear {
    margin-left: 10px;
    font-weight: 300;
}

._filter-modal .clear img {
    height: 12px;
    margin-left: 8px;
}

._filter-modal .head img {
    margin-right: 15px;
}

._filter-modal .close {
    padding-right: 3px;
}

._filter-modal .close .close-svg {

}

._filter-modal ._filters .value {
    margin-left: 44px;
}

._filter-modal ._filters .switch-filter {
    flex-direction: row;
}

._filter-modal ._filters .switch-filters {
    padding: 22px 24px 26px 26px;
    margin-bottom: 0px;
}

._filter-modal ._filters .multi-filter__label {
    padding: 16px 24px 16px 26px;
}

._filter-modal ._filters .multi-filters {
    margin-bottom: 0px;
    border: 0;
}

._filter-modal ._filters .multi-filters .multi-filter {
    border-bottom: 0;
    border-top: 1px solid var(--brand-md-grey);
    padding-bottom: 0;
}

._filter-modal ._filters .multi-filters .filter-value {
    margin-bottom: 10px;
    color: var(--brand-dark-grey);
    font-size: 14px;
}

@media only screen and (min-width: 701px) {
    ._filter-modal{
        display: none;
    }
}
