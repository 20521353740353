._create-user {
    background-color: var(--light-grey);
    margin-bottom: 5px;
    width: 100%;
}

._create-user .title {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
}

._create-user .btn-secondary {
    color: var(--white);
    width: 166px;
    height: 44px;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
    background: var(--brand-md-blue);
    border: 2px solid var(--brand-md-blue);
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    text-align: center;
    margin-left: 10px;
}

._create-user .btn-secondary:hover {
    font-weight: 600;
}

._create-user .btn-secondary:disabled {
    background: var(--brand-md-grey);
    cursor: not-allowed;
    border: 2px solid var(--brand-md-grey);
    color: white;
}

._create-user .back{
    color: var(--brand-dark-blue);
    margin: 32px 0px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    cursor: pointer;
}

._create-user .back:hover {
    font-weight: 800
}

._create-user .divider {
    width: 100%;
    margin: 25px 0px;
    background-color: #CDCDCD;
    border: none;
    height: 2px;
}

._create-user .field-set {
    border: none;
}

._create-user .section-row {
    display: flex;
    margin: 20px 0px;
}

._create-user input {
    display: block;
    height: 40px;
    box-sizing: border-box;
    border: 1px solid var(--brand-md-grey);
    border-radius: 5px;
    font-style: normal;
    font-size: 16px;
    line-height: 16px;
    color: var(--black);
    margin: 8px 0px;
    padding: 8px 16px;

}

._create-user .input {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
}

.input input[type="text"]:focus {
    border: 2px solid var(--brand-md-blue);
    outline: none;
}

._create-user label {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
}

._create-user ._dropdownField {
    margin-top: 20px;
}

._create-user .checkbox-section {
    display: flex;
    align-items: center;
}

._create-user .checkbox-section input {
    height: 15px;
}

._create-user .roles-section {
    margin: 20px 0px;
}

input.disabled-checkbox:checked {
    accent-color: var(--brand-dark-grey);
    cursor: not-allowed;
}

._create-user .checkbox-label {
    padding: 0px 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
}

._create-user .sub-heading {
    padding: 18px;
}

._create-user .error-message {
    color: var(--brand-red);
    padding: 20px;
}

._create-user ._dropdownField .error-message {
    margin: 0px;
}

@media only screen and (min-width: 600px) {
    ._create-user .main {
        max-width: 1440px;
        display: flex;
        width: 100%;
    }

    ._create-user .content {
        padding-left: 20px;
        width: 73%;
    }

    ._create-user .utility {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 335px;
    }

    ._create-user .sub-heading {
        max-width: 687px;
        width: 100%;
        padding: 10px;
    }

    ._create-user .md-input-field {
        max-width: 335px;
        width: 90%;
        padding: 20px;
        margin-top: 8px;
        margin-right: 6.4em;
    }

    ._create-user .lg-input-field {
        max-width: 695px;
        width: 75%;
        height: 36px;
        padding: 20px;
        margin-top: 8px;
    }

    ._create-user .md-content-provider {
        max-width: 473px;
        width: 100%;
        height: 47.5px;
        border-radius: 5px;
        margin-top: 8px;
    }

}

@media only screen and (min-width: 1480px) {
    ._create .main {
        padding-left: 0px;
    }
}