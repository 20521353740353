._create-org {
    background-color: var(--light-grey);
    margin-bottom: 5px;
    width: 100%;
}

._create-org .title {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
}

._create-org .btn-secondary {
    color: var(--white);
    width: 166px;
    height: 44px;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
    background: var(--brand-md-blue);
    border: 2px solid var(--brand-md-blue);
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    text-align: center;
    margin: 24px 0;
}

._create-org .btn-secondary:hover {
    font-weight: 600;
}

._create-org .btn-secondary:disabled {
    border-radius: 8px;
    background: var(--brand-md-grey);
    cursor: not-allowed;
    border: 2px solid var(--brand-md-grey);
    color: white;
    cursor:not-allowed;
}

._create-org .btn-secondary:disabled:hover {
    font-weight: 500;
}

._create-org .btn-primary {
    color: var(--brand-md-blue);
    width: 255px;
    height: 44px;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
    background: var(--white);
    border: 2px solid var(--brand-md-blue);
    border-radius: 8px;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    text-align: center;
    margin-left: 10px;
}

._create-org .btn-primary:hover {
    font-weight: 600;
}

._create-org .back{
    color: var(--brand-dark-blue);
    margin: 17px 0px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    cursor: pointer;
}

._create-org .back:hover{
    font-weight: 800
}

._create-org .divider {
    width: 100%;
    margin: 20px 0px 10px 0;
    background-color: var(--brand-md-grey);
    border: none;
    height: 2px;
}

._create-org .field-set {
    border: none;
    padding-left: 0;
}

._create-org .section-row {
    display: flex;
    margin: 20px 0px;
}
._create-org input {
    display: block;
    height: 40px;
    box-sizing: border-box;
    border: 1px solid var(--brand-md-grey);
    border-radius: 5px;
    font-style: normal;
    font-size: 16px;
    line-height: 16px;
    color: var(--black);
    margin: 8px 0px;
    padding: 8px 16px;

}

._create-org .input {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 20px;
}

._create-org ._dropdownField {
    max-width: 695px;
    width: 75%;
    margin-bottom: 20px;
}

.input input[type="text"]:focus {
    border: 2px solid var(--brand-md-blue);
    outline: none;
}

._create-org label {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
}

._create-org .upload-org-logo-wrapper {
    display: flex;
    flex-direction: column;
}

._create-org .upload-org-logo-wrapper label {
    padding-bottom: 16px;
}

._create-org .upload-org-logo-wrapper .btn-secondary {
    background-color: var(--white);
    color: var(--brand-md-blue);

}

._create-org .upload-org-logo-wrapper .logo-upload-requirements {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: var(--brand-dark-gray);
}

._create-org #error-msg {
    color: var(--brand-red);
    padding: 0px;
    margin-bottom: 20px;
}

._create-org .error-message {
    display: block;
    color: var(--brand-red);
    margin-bottom: 10px;
}

._create-org #submit:disabled,._create-org .upload-org-logo-wrapper .btn-secondary.alternate:disabled  {
    background: var(--brand-md-grey);
    cursor: not-allowed;
    border: 2px solid var(--brand-md-grey);
    color: white;
    cursor:not-allowed;
}

._create-org .upload-org-logo-wrapper .uploaded-logo-container {
    width: 217px;
    height: 105px;
    background: var(--white);
    border: 1px solid var(--brand-md-grey);
    margin: 24px 0 8px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

._create-org .upload-org-logo-wrapper .uploaded-logo-container img {
    position: relative;
    width: 70%;
    height: auto;
    object-fit: contain;
    max-height: 90px;
}

._create-org .locked-field-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: var(--black);
    margin-bottom: 16px;
}

._create-org .locked-field-value {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: var(--brand-dark-gray);
    margin-bottom: 32px;
}

._create-org .upload-org-logo-wrapper .btn-secondary.alternate {
    margin-top: 0;
}

._create-org .upload-org-logo-wrapper .btn-secondary.alternate:hover,
._create-org .upload-org-logo-wrapper label:hover {
    cursor: pointer;
}

#modifyOrg {
    margin-top: 0;
    width: fit-content;
    padding: 0 30px;
    font-weight: 700;
}

._create-org input:disabled {cursor: not-allowed;}

@media only screen and (min-width: 600px) {
    ._create-org .main {
        max-width: 1440px;
        display: flex;
        width: 100%;
    }

    ._create-org .content {
        padding-left: 20px;
        width: 73%;
    }

    ._create-org .utility {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 335px;
    }

    ._create-org .sub-heading {
        max-width: 687px;
        width: 100%;
        padding: 10px 10px 20px 0;
    }

    ._create-org .md-input-field {
        max-width: 335px;
        width: 90%;
        padding: 20px;
        margin-top: 8px;
        margin-right: 6.4em;
    }

    ._create-org .lg-input-field {
        max-width: 695px;
        width: 75%;
        height: 36px;
        padding: 20px;
        margin-top: 8px;
    }
    ._create-org .lg-input-field:disabled {
        background-color: var(--brand-md-grey);
        cursor:not-allowed;
    }

    ._create-org .md-content-provider {
        max-width: 473px;
        width: 100%;
        height: 47.5px;
        border-radius: 5px;
        margin-top: 8px;
    }

}

@media only screen and (min-width: 1480px) {
    ._create-org .main {
        padding-left: 0px;
    }
}
