.date .invalid {
    border: 1px solid var(--brand-red);
    box-sizing: border-box;
    border-radius: 5px;
}

.date {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: var(--black);
}

.react-datepicker__header {
    background-color: var(--brand-md-blue);
}

.react-datepicker__current-month {
    color: var(--white);
}

.react-datepicker__day-name {
    color: var(--white);
}

.date .error-message {
    color: var(--brand-red);
    padding: 20px 0px;
    font-size: 14px;
    font-weight: 400;
}

.date .input-title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: var(--black);
}

.date input[type="date"]:focus {
    border: 2px solid var(--brand-md-blue);
    outline: none;
}

.date input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

.date input[type="text"]:disabled {
    background-color: var(--white);
}

.date input[type="number"]:disabled {
    background-color: var(--white);
}

.date input[type="date"]:disabled {
    background-color: var(--white);
}