._confirmation {
    background-color: var(--light-grey);
    display: contents;
}

._confirmation .confirmed-submit-resource .btn-secondary {
    color:  var(--white);
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
    background: var(--brand-md-blue);;
    border: 2px solid var(--brand-md-blue);
    border-radius: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
}
._confirmation .confirmed-submit-resource .btn-secondary:hover {
    font-weight: 600;
}

._confirmation .confirmation-title {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 20px;
}

._confirmation .confirmation-message {
    margin: 24px 0px 32px 0px;
}

._confirmation .post-results-error-message {
    color: var(--brand-red);
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 10%;
}


@media only screen and (min-width: 600px) {
    ._confirmation .confirmed-main {
        max-width: 1440px;
        padding: 33px 20px 48px 20px;
        width: 100%;
        padding: 2% 0 30% 0;
    }

    ._confirmation .confirmed-submit-resource .content {
        padding-left: 24%;
        width: 96%;
    }

    ._confirmation .confirmed-submit-resource .btn-secondary {
        width: 213px;
        height: 44px;
        margin-bottom: 20px;
    }
}