._results {
  background-color: var(--light-grey);
  padding: 0px 20px;
  display: flex;
}

._results span.desktop-only {
  display: none;
}

._results .section-title {
  font-size: 20px;
  font-weight: 400;
  color: var(--brand-dark-gray-2);
}

#results-summary-title {
  max-width: 70%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--brand-dark-blue);
}

._results .title-bar {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
}

._results .sort-by__wrapper {
  display: flex;
  padding-left: 0px;
}

._results ._drop-down {
  background-color: var(--white);
  padding: 6.5px 15px;
  border-radius: 4px;
  min-width: 182px;
  z-index: 1;
}

._results ._drop-down .dropdown-selection {
  top: 32px;
  left: -1px;
}

._results .tile-row {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

._results .intermediary-tile-row {
  display: none;
}

._results ._tile {
  width: 335px;
}

._results .search-results .title-bar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

._results .void {
  box-shadow: none;
}

._results .load-more {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 22px 0px 48px 0px;
}

._results .load-more button {
  height: 44px;
  width: 207px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  color: var(--brand-md-blue-2);
  border: 1px solid var(--brand-dark-blue);
}

._results .load-more button:hover {
  border: 4px solid var(--brand-md-blue);
}

._results .no-results {
  padding: 20px;
  font-size: 20px;
}

._results .error-section {
  color: var(--brand-red);
  padding: 20px;
  font-size: 20px;
}

._results .filter-bar {
  display: none;
}

@media only screen and (min-width: 600px) {
  ._results {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  ._results__content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 3% 0px 0px 0px;
    margin-bottom: 0px;
    max-width: 1440px;
  }

  ._results span.mobile-only {
    display: none;
  }

  ._results span.desktop-only {
    display: inline;
  }

  ._results .sort-by__wrapper {
    padding-left: 8px;
  }

  ._results .filter-bar {
    margin-left: 20px;
    margin-right: 1%;
    width: 313px;
    display: block;
  }

  ._results .search-results .title-bar {
    flex-direction: row;
    padding: 0 10px;
  }

  ._results .search-results {
    width: 75%;
  }

  ._results .result {
    width: 100%;
    min-height: 15vh;
    padding: 1% 1%;
    display: flex;
    flex-direction: column;
    align-items: space-evenly;
    justify-content: space-evenly;
  }

  ._results .result .summary {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  ._results .result .logo {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ._results .result .summary-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
  }

  ._results .result .endorsement {
    text-align: right;
  }

  ._results .result .metadata {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }

  ._results .void {
    flex-grow: 1;
    max-width: 335px;
    border: 0;
    cursor: default;
  }

  ._results .load-more {
    margin-top: 44px;
    margin-bottom: 52px;
  }

  ._results ._drop-down .dropdown-selection {
    top: 45px;
  }

  .filter-bar__title_wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;
  }

  .filter-bar__clear-filters_wrapper {
    display: flex;
    align-items: center;
  }

  .filter-bar__clear-filters_wrapper:hover {
    cursor: pointer;
  }

  .filter-bar__clear-filters_text {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: var(--brand-md-blue);
  }

  .filter-bar__clear-filters_x {
    width: 10px;
    height: 10px;
    position: relative;
    margin-left: 8px;
  }

  .filter-bar__clear-filters_x:after {
    content: '';
    height: 10px;
    box-shadow: 0 0 0 0.75px var(--brand-md-blue);
    position: absolute;
    transform: rotate(45deg);
    left: 6px;
  }

  .filter-bar__clear-filters_x:before {
    content: '';
    height: 10px;
    box-shadow: 0 0 0 0.75px var(--brand-md-blue);
    position: absolute;
    transform: rotate(-45deg);
    left: 6px;
  }
}

@media (min-width: 600px) and (max-width: 960px) {
  ._results .intermediary-tile-row {
    display: none;
  }

  ._results .search-results .title-bar {
    flex-direction: column;
  }
}

@media screen and (max-width: 600px) {
  ._results {
    justify-content: center;
  }
}

@media only screen and (min-width: 660px) {
  ._results ._drop-down .dropdown-selection {
    top: 31px;
    z-index: 99;
  }
}

@media screen and (max-width: 1050px) {
  #results-summary-title {
    max-width: unset;
    white-space: normal;
    overflow: visible;
    text-overflow: initial;
  }

  ._results .search-results .title-bar {
    gap: 16px;
    margin-bottom: 6px;
  }

  ._results .load-more {
    margin-bottom: 25px;
  }

  ._results .load-more button {
    width: 100%;
    color: var(--brand-dark-blue);
  }

  ._results {
    padding-bottom: 5px;
  }
}

@media only screen and (min-width: 960px) and (max-width: 1420px) {
  ._results .tile-row {
    display: none;
  }

  ._results .intermediary-tile-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    gap: 19px;
  }
}

@media only screen and (min-width: 1420px) {
  .results .tile-row {
    flex-direction: row;
    gap: 19px;
  }
}