.orgType-dropdown-description {
    color: var(--brand-dark-gray, #69737B);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
}

.dropdown-multi-filter-container {
    background-color: var(--white);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border: 1px solid var(--brand-md-grey);
    border-bottom: none;
}

.dropdown-multi-filter-container .dropdown-multi-filter-title {
    color: var(--brand-md-blue-2);
    padding: 18px 25px 0px 17px;
    font-weight: 600;
}

.dropdown-multi-filter-container .dropdown-multi-filters {
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 20px;
}

.dropdown-multi-filter-container ._filters .multi-filters {
    border: none;
}