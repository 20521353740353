._utility-bar {
    border-radius: 8px;
    background-color: var(--white);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
    padding-top: 16px;
    width: 100%;
}

._utility-bar .org-summary {
    padding: 24px;
}

._utility-bar .org-name {
    overflow-wrap: normal;
}

._utility-bar .logo img {
    max-height: 90px;
    max-width: 100%;
    margin-bottom: 24px;
}

._utility-bar .documents {
    padding: 24px 0px 26px 0px;
    font-size: 18px;
    line-height: 21px;
    font-weight: 300;
    border-top: 1px solid var(--brand-md-grey);
    align-items: flex-start;
    display: flex;
    justify-content: center;
}

._utility-bar .documents .documents__content {
    width: 90%;
}

._utility-bar .documents .title {
    overflow-wrap: normal;
}

._utility-bar .documents .download {
    margin-top: 24px;
    border: 2px solid var(--brand-md-blue);
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    line-height: 14px;
    color: var(--brand-md-blue);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 16px;
    height: 44px;
    overflow-wrap: normal;
    max-width: 221px;
    width: 100%;
    text-decoration: none;
}

._utility-bar .documents .download img {
    padding-top: 2px;
    margin-right: 16px;
}

._utility-bar .icon {
    padding: 10px;
}

._utility-bar .flip {
    transform: rotate(180deg);
}

._utility-bar .multiFilterOptions {
    margin-left: 25px;
    text-align: left;
    overflow-y: scroll;
    margin-bottom: 10px;
    height: 140px;
}

._utility-bar .individualItem {
    display: flex;
    padding: 4px;
}

._utility-bar .option {
    color: var(--brand-dark-grey);
    padding: 0px 0px 0px 0px;
    display: flex;
}

._utility-bar .lisitngStatistics {
    padding: 18px 0px 18px 35px;
    font-size: 18px;
    line-height: 21px;
    font-weight: 300;
    border-top: 1px solid var(--brand-md-grey);
    align-items: flex-start;
    display: flex;
}

._utility-bar .lisitngStatistics .documents__content {
    width: 90%;
}

._utility-bar .lisitngStatistics .title {
    overflow-wrap: normal;
    font-size: 20px;
    margin-left: 30px;
}

._utility-bar .lisitngStatistics .listingCount {
    font-weight: 600;
    font-size: 20px;
    line-height: 21px;
    color: var(--brand-md-blue);
}

div._dropdown-wrapper {
    padding-left: 35px;
    justify-content: left;
}

#utitliyStatistics-drop-down {
    background-color: var(--white);
    padding: 2px 10px;
    border-radius: 4px;
    min-width: 182px;
    border: 2px solid var(--brand-md-blue);
}

._utility-bar .multiFilterOptions .individualItem .listingCount-option {
    font-weight: 600;
    display: flex;
    font-size: 16px;
    line-height: 21px;
    color: var(--brand-md-blue);
    padding: 0px 10px 0px 20px;
    min-width: 65px;
}

._utility-bar .lisitngStatistics .download {
    margin-top: 24px;
    justify-content: flex-start;
    height: 44px;
    overflow-wrap: normal;
    max-width: 300px;
    width: 100%;
    cursor: pointer;
    display: flex;
    padding: 12.5px 32px;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: var(--brand-dark-gray, #69737B);
    color: var(--brand-white, #FFF);
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    pointer-events: none;
}

._utility-bar .lisitngStatistics .active {
    background: var(--brand-medium-blue, #1B3E93);
    color: var(--brand-white, #FFF);
    pointer-events: all;
}

._utility-bar .lisitngStatistics .download img {
    padding-top: 2px;
    margin-right: 16px;
}

._utility-bar ._drop-down {
    padding-left: 20px;
}

._utility-bar div._drop-down .dropdown-selection {
    left: -2px !important;
    border: 2px solid var(--brand-md-blue);
}

._utility-bar .statistics {
    margin-bottom: 40px;
}

._utility-bar .statistics .expanded {
    padding: 0px 20px;
}

@media screen and (max-width: 960px) {
    div._dropdown-wrapper {
        padding-left: 0;
    }
}