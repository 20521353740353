._endorse {
  background-color: var(--light-grey);
}

._endorse .load-more button {
  border-radius: 24px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  color: var(--brand-md-blue);
}

._endorse .filter div._drop-down .dropdown-selection {
  height: 250px;
  overflow-y: scroll;
  left: -1px
}

._endorse ._drop-down {
  background-color: var(--white);
  padding: 8px 15px;
  border-radius: 4px;
}

._endorse .filter ._drop-down {
  min-width: 182px;
  max-width: 233px;
}

._endorse ._drop-down .dropdown-selection {
  top: 32px;
  left: -1px !important;
}

._endorse .filter ._drop-down .dropdown-selection div {
  padding: 5px 20px 5px 20px;
  text-align: left;
  display: flex;
}

._endorse .results .void {
  display: none;
}

._endorse .title {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: var(--black);
}

._endorse .sub-content {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--brand-dark-grey);
  font-style: italic;
}

._endorse .divider {
  width: 100%;
  margin: 25px 0px;
  background-color: #cdcdcd;
  border: none;
  height: 2px;
}

._endorse .back {
  color: var(--brand-dark-blue);
  margin: 32px 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  cursor: pointer;
}

._endorse .back:hover {
  font-weight: 800;
}

._endorse .post-result-msg {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: green;
}

._endorse .back-to-dashboard {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: blue;
}

@media only screen and (min-width: 600px) {
  ._endorse {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  ._endorse__content {
    width: 100%;
    max-width: 1440px;
  }

  ._endorse .main {
    display: flex;
    padding: 33px 20px 48px 20px;
  }

  ._endorse .utility {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 335px;
  }

  ._endorse .content {
    padding-left: 20px;
    width: 73%;
  }

  ._endorse .sub-content {
    max-width: 572px;
    width: 46%;
    margin: 8px 0px;
  }

  ._endorse .content .page-title {
    margin-left: 10px;
  }

  ._endorse .content .results-control {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 24px 10px;
  }

  ._endorse .no-results {
    margin-left: 10px;
  }

  ._endorse .load-more button,
  ._endorse .content .results ._tile,
  ._endorse .content .results .void,
  ._endorse .load-more .void {
    max-width: 335px;
    min-width: 126px;
    width: 33%;
    margin-left: 10px;
    margin-right: 10px;
  }

  ._endorse .load-more {
    display: flex;
    margin-top: 48px;
  }

  ._endorse .results .tile-row {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  ._endorse .results .void {
    display: flex;
    border: none;
    box-shadow: none;
  }

  ._endorse .results .void:hover {
    border: none;
    box-shadow: none;
    cursor: auto;
  }

  ._endorse ._drop-down .dropdown-selection {
    top: 35px;
    left: 0px;
  }
}

@media only screen and (min-width: 1480px) {
  ._endorse .main {
    padding-left: 0px;
  }
}