._browse-desktop {
  display: none;
}

.modal ._browse-mobile {
  width: 100%;
  background-color: var(--white);
  border-radius: 0px 0px 8px 8px;
  position: absolute;
  top: 0;
  left: 0;
}

._browse-mobile {
  display: flex;
  color: var(--brand-md-blue);
}

._browse-mobile .main-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 0px 0px 8px 8px;
}

._browse-mobile .head,
._browse-mobile .category,
._browse-mobile .main-content .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 24px;
  width: 100%;
  font-size: 16px;
}

._browse-mobile .head .back,
._browse-mobile .head .icon,
._browse-mobile .category,
._browse-mobile .main-content .item {
  cursor: pointer;
}

._browse-mobile .head {
  height: 64px;
  font-weight: 600;
  line-height: 18px;
}

._browse-mobile .main-content .filter-group {
  width: 100%;
  max-height: 697px;
  overflow-y: scroll;
}

._browse-mobile .category,
._browse-mobile .main-content .item {
  height: 48px;
  border-top: 2px solid var(--extended-light-blue);
  font-weight: 700;
  line-height: 15px;
}

._browse-mobile .close {
  padding-right: 3px;
  font-size: 20px;
  font-weight: 700;
}

._browse-mobile .close {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 22px;
  width: 20px;
  height: 20px;
}
._browse-mobile .close::after {
  content: '\d7';
  position: absolute;
  top: 0px;
  bottom: 0;
  left: 0;
  right: 0px;
  font-size: 34px;
  font-weight: 500;
  color: #1b3e93;
  text-align: center;
}

._browse-mobile .icon .right {
  transform: rotate(270deg);
}

@media only screen and (min-width: 701px) {
  ._browse-desktop {
    display: flex;
    position: absolute;
    top: 50px;
    right: 20px;
    width: 927px;
    background-color: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    z-index: 1;
  }

  ._browse-mobile {
    display: none;
  }

  ._browse-desktop .category-section {
    width: 100%;
    padding: 37px 36px 46px 54px;
  }

  ._browse-desktop .category-title {
    padding-bottom: 26px;
    color: #1b3e93;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 19px;
  }

  ._browse-desktop .category-container {
    display: flex;
  }

  ._browse-desktop .category-item {
    cursor: pointer;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin: 5px 0px;
    color: #1b3e93;
    font-weight: 400;
  }
  ._browse-desktop .category-item:hover {
    font-weight: 500;
  }

  ._browse-desktop .category-heading {
    font-weight: 700;
    border-bottom: 1px solid #e3ecff;
    padding-bottom: 10px;
  }

  ._browse-desktop .category-heading-title {
    font-weight: 700;
    padding-bottom: 10px;
    margin-bottom: 0px;
  }

  ._browse-desktop .category-heading-columns {
    width: 330px;
    margin: 0px;
    height: 1px;
    background-color: #e3ecff;
    border: none;
    margin-bottom: 14px;
  }

  ._browse-desktop .category-column {
    display: flex;
  }

  ._browse-desktop .category-column-one,
  ._browse-desktop .category-column-two {
    width: 180px;
    display: flex;
    flex-direction: column;
  }

  ._browse-desktop .category-column-one {
    margin-right: 20px;
  }

  ._browse-desktop .categories {
    font-size: 14px;
    color: #1b3e93;
    margin-right: 4rem;
    display: flex;
    flex-direction: column;
    width: 182px;
  }

  ._browse-desktop .categories.two-column {
    width: 360px;
  }

  ._browse-desktop .close {
    cursor: pointer;
    position: absolute;
    top: -2px;
    right: 13px;
    width: 20px;
    height: 20px;
  }
  ._browse-desktop .close::after {
    content: '\d7';
    position: absolute;
    top: 0px;
    bottom: 0;
    left: 0;
    right: 0px;
    font-size: 34px;
    font-weight: 500;
    color: #1b3e93;
    text-align: center;
  }
  ._browse-desktop .close:hover::after {
    font-weight: 700;
  }
}
