._dropdownField_multi-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 24px;
    gap: 8px;
}

._dropdownField_multi-value {
    color: var(--brand-dark-gray);
    background-color: var(--extended-light-blue);
    padding: 6px 16px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 16px;
}

._dropdownField_multi-close {
    all: unset;
    font-weight: 700;
    color: var(--brand-dark-gray);
    transition: fill 0.15s ease-in-out;
    cursor: pointer;
}

._dropdownField_multi-value-inner {
    max-width: 255px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
}

.lg-multi-select {
    max-width: 688px;
    height: 36px;
    width: 68%;
    margin-top: 8px;
}

.md-multi-select {
    max-width: 335px;
    width: 35%;
    height: 36px;
    margin-top: 8px;
}

.sm-multi-select {
    max-width: 216px;
    width: 100%;
    margin-top: 8px;
    height: 36px;
}

.oh-teaching-container {
    flex-direction: column;
    align-items: flex-start;
}

.oh-teaching-value-inner {
    max-width: 100%;
}
