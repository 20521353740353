.input .invalid{
    border: 1px solid var(--brand-red);
    box-sizing: border-box;
    border-radius: 5px;
}

.input .error-message{
    color: var(--brand-red);
    padding: 20px 0px;
    font-size: 14px;
    font-weight: 400;
}

.input  .input-title{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color:  var(--black);
}

.input input[type="date"]:focus {
    border: 2px solid var(--brand-md-blue);
    outline: none;
}
.input input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

.input .subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
}

.input input[type="text"]:disabled{background-color: var(--white); cursor: not-allowed;}
.input input[type="number"]:disabled{background-color: var(--white);}
.input input[type="date"]:disabled{background-color: var(--white);}
