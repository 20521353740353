._tile {
    padding: 8px;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 8px;
    background-color: white;
    width: 335px;
    height: 370px;
    margin: 10px 0px;
    border: 1px solid var(--brand-md-grey);
    justify-content: flex-start;
    position: relative;
}
.active:hover {
    box-shadow: 0 0 10px var(--brand-md-blue);
    cursor: pointer;
}

.active:focus {
    border: 0;
    outline: 4px solid var(--brand-md-blue);
}

._tile .org-bar {
    width: 100%;
    color: var(--brand-darker-gray);
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    padding-top: 20px;
}

._tile .title {
    color: var(--black);
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: 2px;
    font-size: 22px;
    line-height: 26px;
}

._tile .short-desc {
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 80px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
}

._tile .tile__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    margin-top: -20px;
    text-align: center;
}

._tile .tile-footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    justify-content: flex-end;
    flex-grow: 1;
}

._tile .review-tag {
    align-self: center;
    margin: 8px;
    border-radius: 4px;
    padding: 6px 12px;
    line-height: 20px;
    font-size: 16px;
    font-weight: 400;
    color: #FFF;
    background-color: #132C68;
    border: 1px solid var(--light-grey-3);
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    align-items: center;
    justify-content: center;
    font-family: Inter;
    font-style: normal;
}

._tile .tile-footer .tile-footer__content {
    width:100%;
    scrollbar-color: var(--brand-dark-grey) var(--white);
}

._tile .tile-footer .tile-footer__content::-webkit-scrollbar-track {
    background: var(--white);
  }

._tile .tag {
    border-radius: 4px;
    padding: 6px 12px;
    margin-bottom: 2px;
    line-height: 16px;
    font-size: 12px;
    font-weight: 400;
    color: var(--brand-dark-grey);
    background-color: var(--white);
    border: 1px solid var(--light-grey-3);
    display: block;
    text-transform: uppercase;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

._tile .endorsement .tag {
    background-color: var(--brand-md-blue);
    color: var(--white);
    width: 95px;
}

._tile .tag img {
    width: 14px;
    height: 14px;
    margin-right: 4%;
}

._tile .details {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    margin-top: 10px;
}

._tile .type {
    overflow-x: hidden;
    display: flex;
}

._tile .card__accent {
    height: 30px;
    width: 100%;
    border-radius: 6px 6px 0 0;
}

._tile .tile__icon {
    width: 70px;
    height: auto;
}

._tile .tile__endorsed-badge {
    position: absolute;
    right: 21px;
    top: 18px;
}

._tile .tile__locked-badge {
    position: absolute;
    right: 21px;
    top: 18px;
}

._tile .resource-info {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: var(--brand-dark-gray);
    text-transform: uppercase;
    display: flex;
    padding-top: 8px;
}

._tile .resource-info .resource-start-date {
    padding-right: 3px;
}

._tile .topics .more-tags {
    display: flex;
    align-items: center;
    font-weight: 500;
    min-width: 68px;
    color: var(--brand-md-blue-2);
    text-transform: capitalize;
    font-size: 12px;
    border: 1px solid var(--brand-md-blue-2);
    position: absolute;
    bottom: 0;
    right: 3px;
    cursor: pointer;
}

._tile .topics .more-tags:hover {
    cursor: pointer;
}

._tile .topics .visible-tags {
    position: relative;
}

._tile .topics .visible-tags .tag-more-plus {
    width: 10px;
    height: auto;
    padding-right: 4%;
}

._tile .topics .visible-tags, ._tile .topics .hidden-tags {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 4px;
}

._tile .topics .hidden-tags {
    position: absolute;
    bottom: 6px;
    background: white;
    border: 1px solid var(--brand-md-blue);
    border-radius: 4px;
    left: 6px;
    right: 6px;
    padding: 6px;
    color: var(--brand-dark-gray);
}

._tile .topics .hidden-tags span {
    padding-right: 2px;
    white-space: pre;
}

._tile .topics .visible-tags .full-width {
    max-width: unset;
    width: fit-content;
}

.tile__pipe {
   margin: 0 4px 0 2px;
}

@media only screen and (min-width: 600px) {

    ._tile .short-desc {
        font-size: 16px;
        line-height: 20px;
    }

    ._tile .details {
        display: block;
        margin-top: 0px;
    }

}

@media only screen and (min-width: 1050px) {

    ._tile .details {
        display: flex;
        margin-top: 6px;
    }
}