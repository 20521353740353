:root {
  --brand-md-blue: #1b3e93;
  --brand-md-blue-2: #003865;
  --extended-light-blue: #e3ecff;
  --extended-very-light-blue: #f2f5f8;
  --brand-dark-blue: #132c68;
  --white: #ffffff;
  --brand-dark-gray: #69737b;
  --brand-dark-grey: #69737b;
  --brand-darker-gray: #263238;
  --brand-dark-grey-2: #425563;
  --brand-dark-gray-2: #425563;
  --brand-md-grey: #cdcdcd;
  --light-grey: #f2f5f8;
  --light-grey-2: #d9d9d9;
  --light-grey-3: #dddddd;
  --black: #202020;
  --black-1: #3a3847;
  --brand-red: #e41034;
  --brand-green: #198754;
  --brand-media-type-event: #ffe6d7;
  --brand-media-type-recording: #fff7e0;
  --brand-media-type-document: #efe8ff;
}

* {
  word-wrap: break-word;
  box-sizing: border-box;
  overflow-wrap: anywhere;
  scrollbar-width: thin;
  scrollbar-color: var(--brand-dark-grey) var(--light-grey-2);
  font-family: 'Inter', sans-serif;
}

*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: var(--light-grey-2);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--brand-dark-grey);
  border-radius: 10px;
  border: 1px solid var(--light-grey-2);
}

.backdrop {
  background-color: var(--black);
}

.btn-secondary {
  cursor: pointer;
}

.backdrop * {
  pointer-events: none;
  opacity: 0.8;
}

.modal .close-modal {
  cursor: pointer;
}

.App {
  text-align: center;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  top: 0px;
}

.App-title {
  width: 100%;
  justify-content: left;
  background-color: #282c34;
}

.App-title div {
  padding-left: 2%;
}

.App-account {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: right;
  font-size: calc(2px + 2vmin);
  color: #000000;
  font-weight: 600;
}

.App-account a {
  padding: 5px;
}

.btn-secondary {
  color: var(--brand-md-blue);
  background-color: var(--white);
  border: 1px solid var(--brand-md-blue);
  font-family: 'Inter';
}

.btn-secondary:hover {
  outline: 4px solid var(--brand-md-blue);
  outline: 0;
}

.btn-secondary:focus-visible,
a:focus-visible,
a:focus-visible {
  border: 0;
  outline: 4px solid var(--brand-md-blue);
}

.btn-secondary:focus-visible {
  outline-offset: -4px;
}

.desktop-only {
  display: none;
}

#app-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  flex: 1 0 auto;
}

.modal-backdrop {
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  left: 0;
  z-index: 1040;
  height: 100%;
  background-color: var(--black);
}

dialog.navigate-away-dialog {
  display: flex;
  z-index: 1041;
  padding: 48px;
  border: 0;
  border-radius: 8px;
  align-items: center;
  flex-direction: column;
}

.dialog-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  margin-top: 20%;
}

.dialog-wrapper-upload {
  width: 100%;
  height: 100%;
  display: flex;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  margin-top: 60%;
}

.dialog-content p {
  margin: 5px 0;
  font-weight: 500;
  text-align: center;
}

.dialog-content-upload-modal p {
  margin: 5px 0;
  font-weight: 500;
  text-align: left;
}

.dialog-buttons {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-top: 16px;
}

.dialog-buttons .dialog-button-yes,
.dialog-buttons .dialog-button-no {
  color: var(--white);
  cursor: pointer;
  background: var(--brand-md-blue);
  border: 2px solid var(--brand-md-blue);
  border-radius: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 19px;
  text-align: center;
  width: 220px;
  height: 40px;
}

.dialog-buttons .dialog-button-yes:hover,
.dialog-buttons .dialog-button-no:hover {
  font-weight: 600;
}

.dialog-buttons .dialog-button-yes:focus,
.dialog-buttons .dialog-button-no:focus {
  border: 2px solid var(--white);
  outline: 4px solid var(--brand-md-blue);
  outline-offset: unset;
}

.back-from-replace-file-modal {
  color: var(--brand-dark-blue);
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  cursor: pointer;
  align-self: flex-start;
  margin-bottom: 30px;
}

.back-from-replace-file-modal:hover {
  font-weight: 800;
}

.dialog-buttons .dialog-button-upload-yes {
  color: var(--white);
  cursor: pointer;
  background: var(--brand-md-blue);
  border: 2px solid var(--brand-md-blue);
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-align: center;
  width: 220px;
  height: 40px;
}

.dialog-buttons .dialog-button-upload-no {
  color: var(--brand-md-blue);
  cursor: pointer;
  background: var(--white);
  border: 2px solid var(--brand-md-blue);
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-align: center;
  width: 220px;
  height: 40px;
}

.dialog-buttons .dialog-button-upload-yes:hover,
.dialog-buttons .dialog-button-upload-no:hover {
  font-weight: 600;
}

.dialog-buttons .dialog-button-upload-yes:focus,
.dialog-buttons .dialog-button-upload-no:focus {
  border: 2px solid var(--white);
  outline: 4px solid var(--brand-md-blue);
  outline-offset: unset;
}

/* Card Colors */
.card-document-color {
  background: var(--brand-media-type-document);
}

.card-course-color {
  background: var(--extended-light-blue);
}

.card-recording-color {
  background: var(--brand-media-type-recording);
}

.card-event-color {
  background: var(--brand-media-type-event);
}

.locked-card-color {
  background: var(--light-grey-3);
}

@media only screen and (min-width: 701px) {
  .modal .mobile-only {
    display: none;
  }

  .mobile-only {
    display: none;
  }

  .desktop-only {
    display: flex;
  }

  .backdrop {
    background: none;
  }

  .backdrop * {
    pointer-events: all;
    opacity: 1;
  }
}
