._dropdownField {
    flex-grow: 1;
    min-width: 200px;
}

._dropdownField .dropdown-title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: var(--black);
    margin: 10px 0px;
}

._dropdownField .error-message {
    color: var(--brand-red);
    margin: 12px 0px;
    font-size: 14px;
    font-weight: 400;
}

._dropdownField .dropdown-title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: var(--black);
    margin-top: 10px;
}

.container-below-dropdown {
    transition: margin-top 0.3s ease;
}