._change_password {
    display: flex;
    flex-direction: row;
    width: 55%;
    margin: 9% auto;
    gap: 33px;
}

#_change_password__form_container {
    flex-basis: 57%;
    display: flex;
    flex-direction: column;
}

h1._change_password__header {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    margin: 0;
}

._change_password__hr {
    border: 0;
    height: 0;
    border-top: 1px solid var(--brand-md-grey);
    border-bottom: 1px solid var(--brand-md-grey);
    width: 100%;
    margin: 24px 0;
}

._change_password__form_label {
    margin: 10px 0 5px 0;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
}

._change_password__form_row {
    margin-bottom: 40px;
}

._change_password__form_row_no_errors {
    margin-bottom: 20px;
}

._change_password__form_input {
    margin: 5px 0 0 0;
    padding: 8px 16px; 
    border: 1px solid var(--brand-md-grey);
    border-radius: 5px;
    box-sizing: border-box;
    width: 100%;
}

._change_password__form_submit {
    margin: 10px 0 0 0;
    padding: 12px 25px;
    border: 2px solid var(--brand-md-blue);
    border-radius: 8px;
    background: var(--brand-md-blue);
    width: 48%; 
    font-size: 16px;
    color: white;
    line-height: 19px;
    display: block;
    cursor: pointer;
}

._change_password__form_submit:disabled {
    background: var(--brand-md-grey);
    cursor: not-allowed;
    border: 2px solid var(--brand-md-grey);
}


#_change_password__password_rules {
    flex: 1;
    flex-direction: column;
    display: flex;
    justify-content: center;
    color: var(--brand-dark-grey);
    padding-bottom: 20px;
}

._change_password__password_rules__title {
    margin: 0;
    font-weight: 700;
}

._change_password__password_rules__list {
    padding-left: 20px;
    margin-top: 4px;
}

._change_password__form_error {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: var(--brand-red);
    margin-top: 8px;
}

._change_password__form_success {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: var(--brand-green);
    margin-top: 8px;
}

._change_password__form_disclaimer {
    margin: 0 0 24px 0;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    font-style: italic;
    color: var(--brand-dark-gray);
    white-space: nowrap;
}

form > div._change_password__form_row:nth-child(2) {
    margin-bottom: 20px;
}

@media screen and (max-width: 1200px) {
    ._change_password {
        width: 90%;
    }
    #_change_password__form_container {
        flex-basis: unset;
        width: 453px;
    }
}

@media screen and (max-width: 800px) {
    ._change_password {
        width: 95%;
    }
}

@media screen and (max-width: 600px) {
    ._change_password {
        width: 100%;
        padding: 0 20px;
        flex-direction: column;
    }
    ._change_password__form_submit {
        width: 100%;
    }
    ._change_password__form_disclaimer {
        white-space: normal;
    }
    #_change_password__form_container {
        width: 100%;
    }
}
