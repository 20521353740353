._review {
  background-color: var(--light-grey);
}

#overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 2;
  cursor: pointer;
}

._review .load-more button {
  border-radius: 24px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  color: var(--brand-md-blue);
}

._review .filter ._drop-down .dropdown-selection {
  height: 250px;
  overflow-y: scroll;
}

._review ._drop-down {
  background-color: var(--white);
  padding: 8px 15px;
  border-radius: 4px;
  min-width: 182px;
  max-width: 233px;
}

._review .filter ._drop-down {
  width: 335px;
}

._review ._drop-down .dropdown-selection {
  top: 32px;
  left: 0px;
}

._review .filter ._drop-down .dropdown-selection div {
  padding: 5px 16px 5px 16px;
  text-align: left;
  display: flex;
}

._review .results .void {
  display: none;
}

._review .review-title {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}

._review .title {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: var(--black);
}

._review .sub-content {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--brand-dark-grey);
  font-style: italic;
}

._review .divider {
  width: 100%;
  margin: 25px 0px;
  background-color: #cdcdcd;
  border: none;
  height: 2px;
}

._review .back {
  color: var(--brand-dark-blue);
  margin: 32px 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  cursor: pointer;
}

._review .back:hover {
  font-weight: 800;
}

._review .back:focus-visible {
  border: 0;
  outline: 4px solid var(--brand-md-blue);
}

.review_listing_popup {
  position: absolute;
  top: 260px;
  left: 500px;
  width: 676px;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 12%);
  z-index: 1;
  justify-content: center;
  display: inline-flex;
  padding: 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 8px;
  background: var(--white);
}

.review_listing_popup_content {
  width: 596px;
}

.review_listing_popup_title {
  color: var(--brand-medium-blue, #1B3E93);
  /* Title */
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px; /* 118.182% */
}

.review_listing_popup_sub_title {
  display: flex;
  flex-direction: column;
  padding: 15px 0px;
  color: #000;
  /* Description */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

._review .post-result-msg {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: green;
}

.review_listing_popup .btn-secondary {
  color: var(--white);
  min-width: 213px;
  height: 44px;
  justify-content: space-evenly;
  align-items: center;
  background: var(--brand-md-blue);
  border: 2px solid var(--brand-md-blue);
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-align: center;
}
.review_listing_popup .btn-secondary:hover {
  font-weight: 600;
}

.review_listing_popup .btn-secondary:disabled {
  font-weight: 500;
  cursor: not-allowed;
  background: var(--brand-md-grey);
  border: none;
  color: var(--brand-md-blue);
}

.review_listing_popup .btn-secondary:focus-visible {
  border: 2px solid var(--white);
  outline: 4px solid var(--brand-md-blue);
  outline-offset: unset;
}

.review_listing_popup button.alternate {
  color: var(--brand-md-blue);
  background-color: var(--white);
  margin-left: 32px;
}

@media only screen and (min-width: 600px) {
  ._review {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  ._review__content {
    width: 100%;
    max-width: 1440px;
  }

  ._review .main {
    display: flex;
    padding: 33px 20px 48px 20px;
  }

  ._review .utility {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 335px;
  }

  ._review .content {
    padding-left: 20px;
    width: 73%;
  }

  ._review .sub-content {
    max-width: 572px;
    width: 46%;
    margin: 8px 0px;
  }

  ._review .content .page-title {
    margin-left: 10px;
  }

  ._review .content .results-control {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 24px 10px;
  }

  ._review .no-results {
    margin-left: 10px;
  }

  ._review .load-more button,
  ._review .content .results ._tile,
  ._review .content .results .void,
  ._review .load-more .void {
    max-width: 335px;
    min-width: 126px;
    width: 33%;
    margin-left: 10px;
    margin-right: 10px;
  }

  ._review .load-more {
    display: flex;
    margin-top: 48px;
  }

  ._review .results .tile-row {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  ._review .results .void {
    display: flex;
    border: none;
    box-shadow: none;
  }

  ._review .results .void:hover {
    border: none;
    box-shadow: none;
    cursor: auto;
  }

  ._review div._drop-down .dropdown-selection {
    top: 35px;
    left: -1px;
  }
}

@media only screen and (min-width: 1480px) {
  ._review .main {
    padding-left: 0px;
  }
}