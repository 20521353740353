._create {
  background-color: var(--light-grey);
}

._create .content .actions button {
  min-height: 83px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--brand-md-blue);
  border-radius: 10px;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  overflow-wrap: normal;
  word-break: normal;
  text-align: left;
  padding: 0px;
}

._create .content .actions label {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  overflow-wrap: normal;
  word-break: normal;
  text-align: left;
  padding: 0px;
  cursor: pointer;
}

._create .content .actions button.alternate {
  border: 1px dashed var(--brand-md-blue);
  background-color: var(--extended-light-blue);
}

._create .content .actions .success {
  color: var(--brand-green);
}

._create .content .actions .failure {
  color: var(--brand-red);
}

._create .load-more button {
  border-radius: 24px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  color: var(--brand-md-blue);
}

._create .filter {
  min-width: 155px;
}

._create ._drop-down {
  background-color: var(--white);
  padding: 8px 15px;
  border-radius: 4px;
  min-width: 182px;
}

._create .expanded {
  border-radius: 4px 4px 0px 0px;
}

._create ._drop-down .dropdown-selection {
  top: 32px;
  left: -1px !important;
}

._create .results .void {
  display: none;
}

._create .back {
  color: var(--brand-dark-blue);
  margin: 32px 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  cursor: pointer;
}

._create .back:hover {
  font-weight: 800;
}

._create .back:focus-visible {
  border: 0;
  outline: 4px solid var(--brand-md-blue);
}

._create .title {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
}

._create .divider {
  width: 100%;
  margin: 25px 0px;
  background-color: #cdcdcd;
  border: none;
  height: 2px;
}

._create .submit-resource .btn-secondary {
  color: var(--white);
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
  background: var(--brand-md-blue);
  border: 2px solid var(--brand-md-blue);
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-left: 20px;
  text-align: center;
}

._create .submit-resource .btn-secondary:hover {
  font-weight: 600;
}

._create .submit-resource .btn-secondary:disabled {
  border: none;
  font-weight: 700;
  background-color: var(--light-grey-2);
  color: var(--brand-md-blue);
  cursor: not-allowed;
}

._create .modify-resource .btn-secondary {
  color: var(--white);
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
  background: var(--brand-md-blue);
  border: 2px solid var(--brand-md-blue);
  border-radius: 8px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  margin-left: 20px;
  text-align: center;
}

._create .modify-resource .btn-secondary:hover {
  border: 3px solid var(--brand-md-blue);
  font-weight: 400;
}

._create .modify-resource .btn-secondary:disabled {
  border: none;
  font-weight: 700;
  background-color: var(--light-grey-2);
  color: var(--brand-md-blue);
  cursor: not-allowed;
}

._create .modify-resource button.alternate {
  color: var(--brand-md-blue);
  background-color: var(--white);
}

._create .modify-resource button.alternate:disabled {
  font-weight: 700;
  cursor: not-allowed;
  background-color: var(--light-grey-2);
  border: 2px solid var(--light-grey-2);
}

._create .modify-resource .successMessage {
  padding: 20px 0px 0px 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: var(--brand-green);
}

._create .submit-title {
  width: 63%;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
}

._create .submit-content {
  width: 64%;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

._create .submit-list-item {
  margin: 20px 0px;
}

._create .back-to-listing {
  color: var(--brand-dark-blue);
  margin: 32px 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  cursor: pointer;
}

._create .back-to-listing:hover {
  font-weight: 800;
}

._create ._confirmation .confirmed-main {
  padding: 5% 0 30% 0;
}

@media only screen and (min-width: 600px) {
  ._create {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  ._create .main {
    max-width: 1440px;
    display: flex;
    padding: 33px 20px 48px 20px;
    width: 100%;
  }

  ._create .utility {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 335px;
  }

  ._create .content {
    padding-left: 20px;
    width: 73%;
  }

  ._create .content .actions {
    display: flex;
    width: 100%;
  }

  ._create .content .results-control {
    display: flex;
    justify-content: space-between;
    margin: 24px 10px;
  }

  ._create .no-results {
    margin-left: 10px;
  }

  ._create .content .actions button,
  ._create .content .actions .spacer,
  ._create .load-more button,
  ._create .content .results ._tile,
  ._create .content .results .void,
  ._create .load-more .void {
    max-width: 335px;
    min-width: 126px;
    width: 33%;
    margin-left: 10px;
    margin-right: 10px;
  }

  ._create .content .actions button>img,
  ._create .content .actions label>img {
    margin-right: 22px;
  }

  ._create .content .actions button>span,
  ._create .content .actions label>span {
    width: 57%;
  }

  ._create .load-more {
    display: flex;
    margin-top: 48px;
  }

  ._create .results .tile-row {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  ._create .results .void {
    display: flex;
    border: none;
    box-shadow: none;
  }

  ._create .results .void:hover {
    border: none;
    box-shadow: none;
    cursor: auto;
  }

  ._create ._drop-down .dropdown-selection {
    top: 35px;
    left: 0px;
  }

  ._create .submit-resource .content {
    padding-left: 20%;
    width: 96%;
  }

  ._create .submit-resource .btn-secondary {
    width: 213px;
    height: 44px;
    margin-bottom: 20px;
  }

  ._create .modify-resource .btn-secondary {
    max-width: 194px;
    width: 18%;
    height: 44px;
  }
}

@media only screen and (min-width: 1480px) {
  ._create .main {
    padding-left: 0px;
  }
}