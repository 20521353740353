._resource-view {
  background-color: var(--light-grey);
  margin-bottom: 5px;
  width: 100%;
}

._resource-view .heading-title {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
}
._resource-view .value {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--brand-dark-grey);
  margin: 16px 0px;
}

._resource-view .value-short {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--brand-dark-grey);
  margin: 16px 0px;
  overflow-y: scroll;
  white-space: pre-wrap;
  max-height: 100%;
}

._resource-view .value-long {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--brand-dark-grey);
  margin: 16px 0px;
  overflow-y: scroll;
  white-space: pre-wrap;
  max-height: 100%;
}

._resource-view .value-url {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--brand-md-blue);
  margin: 16px 0px;
}

._resource-view .divider {
  width: 100%;
  margin: 25px 0px;
  background-color: var(--brand-md-grey);
  border: none;
  height: 2px;
}

._resource-view input {
  display: block;
  height: 40px;
  box-sizing: border-box;
  border: 1px solid var(--brand-md-grey);
  border-radius: 5px;
  margin: 8px 0px;
  padding: 8px 16px;
  cursor: pointer;
}

._resource-view input[type='text']:focus {
  border: 2px solid var(--brand-md-blue);
  outline: none;
}

._resource-view .text-area {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: var(--black);
}

._resource-view textarea {
  display: block;
  box-sizing: border-box;
  margin: 8px 0px;
  background: var(--white);
  border: 1px solid var(--brand-md-grey);
  padding: 8px 16px;
}

._resource-view .text-area textarea::placeholder {
  font-family: Arial;
}

._resource-view .text-area textarea[type='textarea']:focus {
  border: 2px solid var(--brand-md-blue);
  outline: none;
}

._resource-view .section-topic {
  margin: 25px 0px;
}

._resource-view .section-field {
  margin-left: 20px;
  flex-grow: 2;
}

._resource-view .section-cost-field {
  margin-left: 20px;
  margin-top: 10px;
  flex-grow: 2;
}

._resource-view .btn-secondary {
  color: var(--white);
  width: 213px;
  height: 44px;
  justify-content: space-evenly;
  align-items: center;
  background: var(--brand-md-blue);
  border: 2px solid var(--brand-md-blue);
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-align: center;
}
._resource-view .btn-secondary:hover {
  font-weight: 600;
}

._resource-view .btn-secondary:disabled {
  font-weight: 500;
  cursor: not-allowed;
  background: var(--brand-md-grey);
  border: none;
  color: var(--brand-md-blue);
}

._resource-view .btn-secondary:focus-visible {
  border: 2px solid var(--white);
  outline: 4px solid var(--brand-md-blue);
  outline-offset: unset;
}

._resource-view button.alternate {
  color: var(--brand-md-blue);
  background-color: var(--white);
  margin-left: 32px;
}

._resource-view button.replace-uploaded-file {
  color: var(--brand-md-blue);
  background-color: var(--white);
}

._resource-view button.upload {
  margin-left: 0px;
}

._resource-view .back {
  color: var(--brand-dark-blue);
  margin: 32px 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  cursor: pointer;
}
._resource-view .back:hover {
  font-weight: 800;
}

._resource-view .upload-section {
  padding: 16px;
}

._resource-view .upload-evaluation-label {
  padding: 20px;
  cursor: pointer;
}

._resource-view .file-types {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--brand-dark-grey);
}

._resource-view .divider-hr {
  width: 100%;
  margin: 32px 0px;
  background-color: #cdcdcd;
  border: none;
  height: 2px;
}
._resource-view .uploaded-file {
  background-color: var(--light-grey-2);
  margin: 0px 10px;
  padding: 10px;
  align-self: center;
  font-weight: 600;
  color: var(--brand-md-blue);
}

._resource-view .uploaded-file-cross {
  margin-left: 10px;
  cursor: pointer;
  font-weight: 800;
}
._resource-view .uploaded-file-cross:hover {
  font-weight: bold;
  font-size: 14px;
}

@media only screen and (min-width: 600px) {
  .section-row.w-42 {
    width: 42% !important;
    display: flex;
    flex-direction: column;
  }

  ._resource-view .section-row {
    margin: 25px 0px;
    display: flex;
    width: 100%;
    display: flex;
  }

  ._resource-view .disabled {
    display: block;
  }

  ._resource-view .disabled .label {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 16px;
  }

  ._resource-view .disabled .comments {
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    white-space: pre-wrap;
  }

  ._resource-view .upload-evaluation {
    margin-top: 25px;
    display: flex;
    width: 100%;
  }

  ._resource-view .lg-input-field {
    max-width: 690px;
    width: 75%;
  }

  ._resource-view .lg-input-field .invalid {
    border: 1px solid var(--brand-red);
  }

  ._resource-view .sm-input-field {
    max-width: 335px;
    width: 100%;
  }

  ._resource-view .vsm-input-field {
    height: 44px;
    max-width: 99px;
    margin-top: 11px;
  }

  ._resource-view .md-input-field {
    max-width: 511px;
    width: 100%;
  }

  ._resource-view .text-area {
    flex-grow: 1;
  }

  ._resource-view .publish-dates .text-area {
    flex-grow: 0;
  }

  ._resource-view .publish-date {
    max-width: 335px;
    width: 35%;
  }

  ._resource-view .lg-text-area {
    height: 200px;
    max-width: 907px;
    width: 84%;
  }

  ._resource-view .md-text-area {
    max-width: 571px;
    height: 200px;
    width: 100%;
  }

  ._resource-view .sm-text-area {
    max-width: 453px;
    height: 100px;
    width: 100%;
  }

  ._resource-view .md-content-provider {
    max-width: 473px;
    width: 100%;
    height: 36px;
    margin-top: 8px;
  }

  ._resource-view .md-dropdown-field {
    max-width: 335px;
    width: 35%;
    height: 36px;
    margin: 20px 0px;
  }

  ._resource-view .lg-dropdown-field {
    max-width: 688px;
    height: 36px;
    width: 68%;
  }

  ._resource-view .sm-dropdown-field {
    max-width: 216px;
    width: 55%;
    height: 40px;
    margin: 20px 0px;
  }

  ._resource-view .sm-dropdown-field-credit {
    max-width: 216px;
    width: 100%;
  }

  ._resource-view .section-row-credit {
    margin: 25px 0px;
    display: flex;
    width: 100%;
  }

  ._resource-view .section-row-credit .credit-type {
    max-width: 216px;
    width: 20%;
  }

  ._resource-view .sm-input-field-credit-hours {
    height: 44px;
    max-width: 99px;
    max-width: 242px;
    width: 100%;
    flex-grow: 0;
  }

  ._resource-view .section-field-sm {
    margin-left: 20px;
  }
}
