._home {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  padding-bottom: 60px;
}

._home .main {
  mix-blend-mode: normal;
}

._home .tile-row .void,
._home .intermediary-tile-row .void {
  visibility: hidden;
}

._home .summary {
  display: flex;
  justify-content: center;
  padding-top: 4%;
  font-weight: 700;
  color: var(--brand-dark-blue);
  font-size: 32px;
  line-height: 41px;
}

._home .summary-detail {
  margin-top: 24px;
  justify-content: center;
  align-items: center;
  color: var(--brand-dark-blue);
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
}

._home .query {
  margin-top: 24px;
  margin-bottom: 38px;
  display: flex;
  font-size: calc(2px + 2vmin);
  align-items: center;
}

._home .query button {
  color: var(--white);
  background-color: var(--brand-md-blue-2);
  border-radius: 10px;
  margin-left: 13px;
  border: none;
}

._home .query img {
  width: 20px;
}

._home .search-bar-wrapper {
  border-radius: 10px;
  border: none;
  text-align: left;
  background-color: var(--white);
  height: 50px;
  display: flex;
  align-items: center;
  flex-grow: 1;
  border: 1px solid var(--black);
}

._home .search-bar {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 17px;
  outline: none;
  border: none;
  flex-grow: 1;
  font-size: 20px;
  font-family: 'Inter';
  font-style: 'Italic';
}

._home .search-bar-wrapper .search-bar:focus-visible {
  outline: none;
}

._home .search-bar-wrapper:hover {
  border: 0;
  outline: 4px solid var(--brand-md-blue);
}

._home .query button {
  width: 40px;
  height: 38px;
  cursor: pointer;
  margin-right: 6px;
}

._home .query button:focus {
  border: 0;
  outline: 4px solid var(--white);
}

._home .query button:hover {
  border: 8px solid var(--brand-md-blue);
  box-shadow: 0 0 10px var(--brand-md-blue);
}

._home input[type='text']:focus {
  border: 0;
  outline: 4px solid var(--brand-md-blue);
}

._home .new-content {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

._home .new-content-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 33px;
  margin-block-start: 0px;
  margin-block-end: 0px;
  padding-left: 20px;
  font-size: 20px;
  line-height: 26px;
  color: var(--brand-md-blue-2);
}

._home .new-content h4 {
  font-weight: 400;
  color: var(--brand-md-blue-2);
}

._home .new-content .view-all {
  display: none;
}

._home .new-content .btn-secondary {
  color: var(--brand-dark-blue);
  font-size: 16px;
  line-height: 17px;
  font-weight: 500;
  height: 35px;
  width: 159px;
  border-radius: 8px;
}

._home .new-content .btn-secondary:hover {
  box-shadow: 0 0 10px var(--brand-md-blue);
}

._home .search-bar-wrapper .query-button:hover {
  border: 8px solid var(--brand-md-blue);
  box-shadow: 0 0 10px var(--brand-md-blue);
}

._home .tile-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

._home .new-content .mobile-only {
  display: flex;
  justify-content: stretch;
  width: 335px;
  margin-top: 20px;
}

._home .new-content .mobile-only button {
  flex-grow: 1;
}

._home .tiles-main {
  overflow-y: scroll;
  width: 100%;
  justify-content: center;
}

._home .main {
  width: '100%';
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-bottom: 80px;
}

._home .mobile-graphic {
  width: 100%;
  transform: translateY(90px);
}

@media only screen and (min-width: 601px) {
  ._home .search-bar-wrapper {
    width: 75%;
    max-width: 618px;
    font-size: 20px;
    height: 60px;
  }

  ._home .search-bar::placeholder {
    font-size: 20px;
    color: var(--brand-md-grey);
  }

  ._home .new-content {
    width: 100%;
    align-items: start;
    max-width: 1440px;
    margin: auto;
  }

  ._home .main {
    max-width: 1440px;
  }

  ._home .new-content h4 {
    font-size: 20px;
    width: 100%;
  }

  ._home .new-content-header {
    padding-right: calc(2vmin + 1px);
    font-size: 20px;
    line-height: 24px;
    padding-left: 19px;
    color: var(--brand-md-blue-2);
  }

  ._home .new-content .view-all {
    display: flex;
    justify-content: flex-end;
    padding-right: 0px;
  }

  ._home .summary {
    justify-content: center;
    align-items: center;
    font-size: 44px;
    line-height: 52px;
    padding-top: 49px;
    width: 70%;
    max-width: 435px;
  }

  ._home .summary-detail {
    margin-top: 25px;
    font-size: 18px;
    line-height: 25px;
    padding: 0px;
    display: flex;
    justify-content: center;
    max-width: 618px;
    color: var(--brand-dark-blue);
  }

  ._home .query {
    margin-top: 40px;
    margin-bottom: 42px;
  }

  ._home .query button {
    width: 50px;
    height: 48px;
  }

  ._home .tile-row {
    flex-direction: row;
    align-items: stretch;
    gap: 19px;
    padding: 0 20px;
    justify-content: space-between;
  }

  ._home .new-content .mobile-only {
    display: none;
  }

  ._home .main-content {
    min-width: 540px;
    margin-bottom: 50px;
    width: 43%;
    margin-left: 16px;
    z-index: 1;
    position: relative;
  }
}

@media screen and (min-width: 800px) {
  ._home .main-content {
    min-width: 614px;
    margin-left: 60px;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1440px) {
  ._home .home-graphic {
    position: absolute;
    top: 61px;
    left: 614px;
    width: 50%;
  }
}

@media screen and (max-width: 1440px) {
  ._home .tile-row {
    display: none;
  }

  ._home .intermediary-tile-row {
    display: flex;
    justify-content: center;
    gap: 19px;
    margin: 0px 19px;
  }
}

@media screen and (max-width: 1075px) {
  ._home .tile-row {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }

  ._home .tile-group {
    display: flex;
    flex-direction: row;
    gap: 19px;
    justify-content: center;
  }

  ._home .intermediary-tile-row {
    display: none;
  }
}

@media screen and (max-width: 740px) {
  ._home .search-bar::placeholder,
  ._home .search-bar {
    font-size: 14px;
  }

  ._home .tile-row {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  ._home .tile-group {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  ._home .intermediary-tile-row {
    display: none;
  }
}

@media screen and (min-width: 1441px) {
  ._home .home-graphic {
    position: absolute;
    top: 61px;
    left: 614px;
    max-width: 726px;
  }

  ._home .main {
    min-width: 1440px;
    max-width: 1440px;
  }

  ._home {
    align-items: center;
  }

  ._home .tile-row {
    display: flex;
    flex-direction: row;
    gap: 19px;
    justify-content: center;
  }

  ._home .tile-group {
    display: flex;
    flex-direction: row;
    gap: 19px;
  }

  ._home .intermediary-tile-row {
    display: none;
  }

  ._home .tiles-main {
    overflow-y: auto;
  }
}

@media screen and (min-width: 1201px) {
  ._home .main {
    display: flex;
    flex-direction: row;
    position: relative;
    margin-bottom: 40px;
  }

  ._home .desktop-graphic {
    margin-top: 50px;
  }
}

@media screen and (max-width: 1200px) {
  ._home .home-graphic {
    max-height: 325px;
  }

  ._home .main {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 600px) {
  ._home .main-content {
    margin-bottom: -80px;
    padding-left: 17px;
    padding-right: 17px;
  }
}
