.text-area .invalid{
    border: 1px solid var(--brand-red);
    box-sizing: border-box;
    border-radius: 5px;
}

.text-area .error-message{
    color: var(--brand-red);
    padding: 20px 0px;
    font-size: 14px;
    font-weight: 400;
}

.text-area  .input-title{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color:  var(--black);
}

.text-area textarea[type="text"]:disabled{
    background-color: var(--white);
}
