footer {
  flex-shrink: 0;
}

.App-footer {
  background-color: var(--brand-md-blue-2);
  color: var(--white);
}

.App-footer .summary {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  border-bottom: 1px solid var(--white);
}

.App-footer .summary .mini-logo {
  display: none;
}

.inner-footer-content-wrapper {
  display: flex;
  align-items: flex-start;
  gap: 38px;
}

.footer-links .footer-link {
  font-weight: 400;
  color: var(--white);
  text-decoration-line: underline;
}

.footer-links .footer-link:focus-visible {
  border: 0;
  outline: 4px solid var(--white);
}

@media only screen and (min-width: 600px) {
  .App-footer {
    padding: 51px 0 81px 0;
    font-family: 'Inter';
  }

  .App-inner-footer {
    flex-direction: row;
    display: flex;
    margin: auto;
    align-items: flex-start;
    justify-content: center;
  }

  .App-footer .summary {
    padding: 0px;
    display: flex;
    align-items: flex-start;
    border-bottom: none;
    flex-direction: column;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    text-align: left;
    flex-basis: 346px;
  }

  .App-footer .footer-address {
    margin-left: 92px;
    flex-direction: row;
    display: flex;
    gap: 18px;
  }

  .App-footer .footer-address .icon {
    width: 17px;
    height: 21px;
    padding-top: 3px;
  }

  .App-footer .footer-address .icon img {
    width: 17px;
    height: 21px;
  }

  .App-footer .footer-address .info {
    display: flex;
    flex-direction: column;
    gap: 3px;
  }

  .App-footer .footer-address .info .mobile-inline {
    display: flex;
    flex-direction: column;
  }

  .App-footer .footer-email {
    display: flex;
    flex-direction: row;
    margin-left: 52px;
    gap: 16px;
    align-items: center;
  }

  .App-footer .footer-email .icon {
    width: 25px;
    height: 16px;
  }

  .App-footer .footer-links {
    margin-left: 52px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    flex: 1;
  }

  .App-footer .summary .mini-logo {
    margin-right: 2%;
    padding-top: 5px;
    display: flex;
  }

  .footer-content-wrapper {
    display: flex;
    align-items: flex-start;
  }
}

@media screen and (max-width: 1200px) {
  .App-inner-footer {
    gap: 81px;
  }

  .App-footer .summary {
    flex-basis: 310px;
  }

  .footer-content-wrapper {
    gap: 38px;
  }

  .App-footer .footer-address,
  .App-footer .footer-email,
  .App-footer .footer-links {
    margin-left: 0;
  }

  .inner-footer-content-wrapper {
    gap: 23px;
    flex-direction: column;
  }
}

@media screen and (max-width: 880px) {
  .App-inner-footer {
    padding: 42px 50px;
    flex-direction: column;
    gap: 0;
  }

  .footer-content-wrapper {
    flex-direction: column;
    margin: auto;
  }

  .App-footer .summary {
    text-align: center;
    flex-basis: unset;
    max-width: 275px;
    line-height: 20px;
    padding-bottom: 32px;
    margin: auto;
    margin-bottom: 48px;
  }

  .App-footer .footer-address,
  .App-footer .footer-email,
  .App-footer .footer-links {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .App-footer .footer-email,
  .App-footer .footer-links {
    margin-left: unset;
    margin-top: 32px;
  }

  .App-footer .footer-address .info .mobile-inline {
    flex-direction: row;
  }

  .App-footer .footer-address {
    text-align: center;
  }

  .App-footer .footer-address .info {
    gap: 0;
    margin-top: 16px;
  }

  .App-footer .footer-address .icon {
    width: 20px;
    height: 23px;
    padding-top: unset;
    margin: auto;
  }

  .App-footer .footer-email .icon {
    display: flex;
    margin: auto;
    justify-content: center;
  }

  .App-footer .footer-email .info {
    text-align: center;
    margin-top: 16px;
  }

  .App-footer .footer-address .icon img {
    width: 20px;
    height: 23px;
  }

  .App-footer .footer-links {
    flex-direction: column;
    display: flex;
  }

  .App-footer .footer-links .footer-link:nth-child(1) {
    margin-bottom: 16px;
  }
}
