._query-bar {
  width: 100%;
  color: var(--brand-dark-gray);
  display: flex;
  flex-direction: column;
  font-size: calc(2px + 2vmin);
  font-weight: 300;
  box-sizing: border-box;
  background-color: white;
  align-items: stretch;
  justify-content: space-between;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  padding: 0 20px;
}

._query-bar .logo {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: var(--brand-dark-gray);
  font-size: 24px;
  cursor: pointer;
  font-weight: 300;
}

._query-bar .logo .filters {
  display: flex;
}

._query-bar .logo .filters button {
  border-radius: 8px;
}

#logo-content {
  margin-right: 28px;
  flex-grow: 1;
}

#logo-content:focus-visible {
  border: 0;
  outline: 4px solid var(--brand-md-blue);
}

#browse-dropdown > .browse-dropdown-wrapper {
  width: 135px;
  height: 44px;
  justify-content: center;
  display: flex;
  gap: 17px;
  align-items: center;
}

#browse-dropdown {
  width: 135px;
  padding: 0;
  height: 44px;
}

._query-bar .logo-img {
  margin-right: 15px;
}

._query-bar .logo .filters img {
  display: flex;
  width: auto;
}

._query-bar .logo .filters {
  font-size: 22px;
  line-height: 26px;
  font-weight: 300;
}

._query-bar .logo img {
  width: 150px;
}

._query-bar .search-bar {
  display: flex;
  justify-content: flex-start;
  padding: 20px 20px;
  background-color: var(--extended-very-light-blue);
}

._query-bar .search-bar input {
  box-sizing: border-box;
  border-radius: 10px;
  flex-grow: 1;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  outline: none;
  border: none;
  padding-left: 17px;
  text-align: left;
  background-color: var(--white);
  font-style: italic;
}

.query-bar-search-wrapper {
  display: flex;
  border-radius: 8px;
  border: 1px solid var(--brand-dark-gray);
  flex-grow: 1;
  align-items: center;
  background-color: var(--white);
  height: 42px;
  max-width: 618px;
}

._query-bar .search-bar .submit {
  border-radius: 8px;
  border: none;
  color: var(--white);
  background-color: var(--brand-dark-blue);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 36px;
  cursor: pointer;
  margin-right: 2px
}

._query-bar .search-bar .query-bar-search-wrapper:hover,
._query-bar .search-bar .query-bar-search-wrapper:focus,
._query-bar .search-bar .query-bar-search-wrapper:has(input:focus-visible) {
  border: 2px solid var(--brand-dark-blue);
  outline: none;
}
._query-bar .search-bar .submit:hover {
  border: 8px solid var(--brand-md-blue);
  box-shadow: 0 0 10px var(--brand-md-blue);
}

._query-bar .search-bar .submit:focus-visible {
  border: 2px solid var(--white);
  outline: 3px solid var(--brand-md-blue);
}

._query-bar .search-bar .submit img {
  width: 18px;
}

._query-bar .filters {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

._query-bar .search-bar .filters {
  display: none;
}

._query-bar .btn-secondary {
  font-weight: 600;
  border-radius: 8px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
}

._query-bar .btn-secondary .img {
  margin-top: 3px;
  margin-right: 8px;
}

._query-bar .desktop-only {
  display: none;
}

._query-bar .close-modal {
  pointer-events: all;
  position: absolute;
  opacity: 1;
  top: 0;
  left: 0;
  background-color: white;
}

._query-bar .filter-icon {
  width: 27.6px;
  height: 27.5px;
}

._query-bar .filter-btn {
  padding: 6px;
  width: 50px;
  height: 50px;
  border: 1px solid var(--brand-dark-blue);
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 700px) {
  ._query-bar {
    display: flex;
    width: 100%;
    justify-content: center;
    background-color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
    position: relative;
    z-index: 2;
  }

  ._query-bar__content {
    width: 100%;
    color: var(--brand-dark-gray);
    display: flex;
    font-size: calc(2px + 2vmin);
    font-weight: 300;
    box-sizing: border-box;
    background-color: none;
    min-height: 120px;
    border: none;
    align-items: center;
    justify-content: space-between;
    box-shadow: none;
    max-width: 1440px;
  }

  ._query-bar .filter-btn {
    display: none;
  }

  ._query-bar .logo {
    width: auto;
    margin: 0px 0px 0px 0px;
    padding-right: 0px;
    justify-content: center;
  }

  ._query-bar .logo .filters {
    display: none;
  }

  ._query-bar .logo img {
    display: flex;
  }

  ._query-bar .logo div {
    font-size: 24px;
    line-height: 26px;
    font-weight: 300;
  }

  ._query-bar .search-bar {
    width: 60%;
    margin: 10px 0px;
    background: none;
    padding: 0px 0px;
    justify-content: flex-end;
    position: relative;
    align-items: center;
    gap: 22px;
  }

  ._query-bar .search-bar .submit img {
    height: 20px;
    width: 20px;
    display: flex;
  }

  ._query-bar .btn-secondary {
    font-size: 16px;
  }

  ._query-bar .btn-secondary:hover {
    border: 4px solid var(--brand-dark-blue);
    outline: 0;
  }

  ._query-bar .search-bar .filters {
    display: flex;
  }

  ._query-bar .desktop-only {
    display: flex;
  }

  ._query-bar #logo-content {
    width: 347px;
  }
}

@media only screen and (min-width: 800px) {
  ._query-bar {
    flex-direction: row;
  }
}

@media only screen and (min-width: 1440px) {
  ._query-bar__content {
    padding-left: 0px;
  }
}

@media only screen and (max-width: 900px) {

  #browse-dropdown > .browse-dropdown-wrapper {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  ._query-bar .search-bar {
    width: 64%;
  }
}

@media screen and (max-width: 700px) {

  ._query-bar .filter-btn {
    margin-left: 21px;
  }

  ._query-bar .search-bar .query-bar-search-wrapper {
    height: 50px;
  }

  ._query-bar .search-bar .submit {
    width: 39px;
    height: 39px;
    margin-right: 4px;
  }

  #browse-dropdown-mobile > .browse-dropdown-wrapper {
    width: 118px;
    height: 40px;
    gap: 14px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    line-height: 18px;
    align-items: center;
  }

  #browse-dropdown-mobile {
    border-radius: 8px;
  }

  #browse-dropdown-mobile .browse-dropdown-wrapper img {
    width: 16px;
    height: 11px;
  }

  ._query-bar .logo {
    margin: 26px 16px;
  }

  ._query-bar {
    padding: 0;
  }

  ._query-bar .search-bar {
    width: 100%;
    padding: 24px 17px;
  }
}
