._drop-down .dropdown-selection div {
  padding-top: 5px;
  padding-left: 15px;
  padding-bottom: 5px;
  text-align: left;
  width: 100%;
  min-width: 182px;
  cursor: pointer;
}

._drop-down .dropdown-selection div:hover,
._drop-down .dropdown-selection div:focus {
  background-color: var(--extended-light-blue);
  color: var(--brand-md-blue);
  border-left: 1px solid var(--brand-dark-blue);
  padding-left: 14px;
  min-width: 181px;
}

._drop-down .dropdown-selection div:focus-visible {
  border: 2px solid var(--brand-dark-blue);
  outline: none;
}

._drop-down__content {
  display: flex;
  align-items: center;
  height: 100%;
}

._drop-down {
  display: flex;
  align-items: center;
  color: var(--brand-dark-blue);
  font-size: 16px;
  line-height: 20px;
  position: relative;
  padding-bottom: 5px;
  justify-content: space-between;
  cursor: pointer;
  min-width: 182px;
  border: 1px solid var(--brand-dark-blue);
}

._dropdown-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

._dropdown-wrapper .label {
  color: var(--brand-dark-grey);
  margin-right: 8px;
  font-size: 16px;
}

._drop-down .selected-option {
  cursor: pointer;
}

._drop-down .current {
  font-weight: 600;
  color: var(--brand-md-blue);
}

._drop-down .selected-option:hover {
  font-weight: 600;
}

._drop-down .selected-option:focus-visible,
._drop-down .icon:focus-visible  {
  border: 2px solid var(--brand-dark-blue);
  outline: none;
}

._drop-down img {
  width: 13px;
  height: auto;
  margin-left: 3px;
  margin-top: 3px;
}

._drop-down .icon {
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
  padding-left: 5px;
}

._drop-down .dropdown-selection {
  position: absolute;
  background-color: var(--white);
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  width: 100%;
  min-width: 182px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0px 4px 4px #e6e6e6;
  border: 1px solid var(--brand-dark-blue);
  border-top: 0;
    z-index: 99;
}

._drop-down .flip {
  transform: rotate(180deg);
}

/* Basic dropdown styles */
._drop-down[data-type='basic'] {
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  margin-bottom: 32px;
}

._drop-down[data-type='basic'] .basic-dropdown-wrapper {
  flex-direction: row;
  width: 50%;
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: none;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  align-items: center;
  min-height: 42px;
  outline: 0 !important;
  position: relative;
  transition: all 100ms;
  box-sizing: border-box;
  border: 1px solid var(--brand-md-grey);
  padding: 0 16px;
  color: var(--black);
  font-size: 14px;
}

._drop-down[data-type='basic'] .basic-dropdown-wrapper:focus-visible {
  border: 4px solid var(--brand-md-blue);
  outline: 0;
}

._dropdown-wrapper-basic span.label {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: var(--black);
  margin: 10px 0;
}

._drop-down[data-type='basic'] #dropdown-arrow-icon {
  margin-left: auto;
}

._drop-down[data-type='basic'] .dropdown-selection {
  width: 50%;
  top: 100%;
  background-color: var(--white);
  border-radius: 4px;
  box-shadow: 0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);
  margin-bottom: 8px;
  margin-top: 8px;
  z-index: 1;
  box-sizing: border-box;
}

._drop-down[data-type='basic'] .dropdown-selection .dropdown-options-inner-wrapper {
  max-height: 300px;
  overflow-y: auto;
  padding-bottom: 4px;
  padding-top: 4px;
  padding-right: 0;
  position: relative;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
}

._drop-down[data-type='basic'] .dropdown-selection div:hover {
  background-color: unset;
  color: unset;
}

._drop-down[data-type='basic'] .dropdown-selection .dropdown-options-inner-wrapper [class$='-opt'] {
  background-color: var(--white);
  color: var(--black);
  cursor: pointer;
  display: block;
  padding: 8px 16px;
  width: 100%;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  text-align: left;
  font-size: 14px;
}

._drop-down[data-type='basic'] .dropdown-selection div>div:hover {
  background-color: var(--extended-light-blue);
  color: var(--brand-md-blue);
}

._drop-down[data-type='basic'] .dropdown-selection .dropdown-options-inner-wrapper [class$='-opt']:hover,
._drop-down[data-type='basic'] .dropdown-selection .dropdown-options-inner-wrapper [class$='-opt']:focus-visible {
  background-color: var(--brand-md-blue);
  color: var(--white);
}

/* End basic dropdown styles */

@media only screen and (min-width: 600px) {
  ._drop-down {
    padding-left: 5px;
  }

  ._drop-down .icon {
    padding-left: 3px;
  }
}